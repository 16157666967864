import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getLanguageValue,
  ITranslationObject,
} from "../../../../commonUtils/languageFunctionsHelper";
import {
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import { RootState } from "@app/store/configureStore";
import {
  previewParticipantInvitation,
  sendParticipantsInvitation,
} from "../actions";
import {
  ISelectedProfilesList,
  ISendParticipantsInvitationBody,
} from "../activityInviteParticipantsStep";
import { EmailSMSInvitation } from "./emailSMSInvitation";
import { ISavedMessage } from "./invitationMessageListModal";
import { TabNavItem } from "../../../../components/multiTabComponent/tabNavItem";
import { TabContent } from "../../../../components/multiTabComponent/tabContent";
import {
  getInvitationNavTabs,
  InvitationNavTabs,
} from "../../../../commonUtils/invitationFunctionsHelper";
import { ModalComponent } from "../../../../components/modals/modalComponent";
import { setSpinner } from "../../../actions";
import { CustomModal as SMSWarningModal } from "../../../../components/modals/customModal";
import { CustomModal as InvitationStatusModal } from "../../../../components/modals/customModal";
import {
  getEmailStatusMessage,
  getSMSStatusMessage,
} from "../../editActivity/interface";
import { ILanguage } from "@app/containers/commonInterfaces";

interface ISendParticipantsInvitationResponse {
  emailStatusMessage: string;
  emailStatus: number;
  emailAddress: string;
  smsStatusMessage: string;
  smsStatus: number;
  phoneNumber: string;
}

interface ISendParticipantsInvitationResponseState {
  isEmail: boolean;
  participants: ISendParticipantsInvitationResponse[];
}

interface IGroupInvitationProps {
  languageText: ITranslationObject;
  messageTranslation: ITranslationObject;
  languages: ReadonlyArray<ILanguage>;
  cultureValue: string;
  participants: ISelectedProfilesList[];
  emailInvitationSent: boolean;
  smsInvitationSent: boolean;
  handleMessageSentSuccess: (isEmail: boolean) => void;
  handleCloseClick: () => void;
}

export const GroupInvitation = (props: IGroupInvitationProps) => {
  const dispatch = useDispatch();

  const spinner = useSelector((state: RootState) => state.mainReducer.spinner);

  const initialInvitationResponseState: ISendParticipantsInvitationResponseState =
    {
      participants: [],
      isEmail: true,
    };
  const [activeTab, setActiveTab] = useState<string>(InvitationNavTabs.email);
  const [emailLanguage, setEmailLanguage] = useState<string>("");
  const [emailInvitationText, setEmailInvitationText] = useState<string>("");
  const [emailSelectedMessageId, setEmailSelectedMessageId] =
    useState<number>(0);
  const [smsLanguage, setSMSLanguage] = useState<string>("");
  const [smsInvitationText, setSMSInvitationText] = useState<string>("");
  const [smsSelectedMessageId, setSMSSelectedMessageId] = useState<number>(0);
  const [previewHTML, setPreviewHTML] = useState<string>("");
  const [participantWithoutPhNo, setParticipantWithoutPhNo] = useState<
    ISelectedProfilesList[]
  >([]);
  const [invitationStatus, setInvitationStatus] =
    useState<ISendParticipantsInvitationResponseState>(
      initialInvitationResponseState,
    );

  const invitationNavTabs = useMemo(
    () => getInvitationNavTabs(props.languageText),
    [],
  );

  useEffect(() => {
    setEmailLanguage(props.cultureValue);
    setSMSLanguage(props.cultureValue);
  }, []);

  const handleEmailLanguageSelect = (
    selectedItem: IDropdownSelectedItem,
  ): void => {
    setEmailLanguage(selectedItem.value);
  };

  const onEmailTextChange = (value: string): void => {
    if (props.emailInvitationSent) return;
    setEmailInvitationText(value);
  };

  const handleSelectedEmail = (selectedMessage: ISavedMessage): void => {
    setEmailInvitationText(selectedMessage.message);
    setEmailSelectedMessageId(selectedMessage.id);
  };

  const handleSMSLanguageSelect = (
    selectedItem: IDropdownSelectedItem,
  ): void => {
    setSMSLanguage(selectedItem.value);
  };

  const onSMSTextChange = (value: string): void => {
    if (props.smsInvitationSent) return;
    setSMSInvitationText(value);
  };

  const handleSelectedSMS = (selectedMessage: ISavedMessage): void => {
    setSMSInvitationText(selectedMessage.message);
    setSMSSelectedMessageId(selectedMessage.id);
  };

  const handleSendInvitation = (isEmail: boolean): void => {
    dispatch(setSpinner(true));

    const languageValue: string = isEmail ? emailLanguage : smsLanguage;
    const languageId: number =
      props.languages.find((language) => language.name === languageValue)?.id ??
      0;

    let updatedParticipants: ISelectedProfilesList[] = props.participants;
    // Filter participants which have ph no.
    if (!isEmail) {
      updatedParticipants = [...props.participants].filter(
        (participant) => participant.phoneNumber,
      );
    }

    const body: ISendParticipantsInvitationBody[] = [];
    updatedParticipants.map((participant) => {
      let isReminder = false;
      if (isEmail && participant.emailStatus && participant.emailStatus > 1)
        isReminder = true;
      else if (!isEmail && participant.smsStatus && participant.smsStatus > 1)
        isReminder = true;

      body.push({
        id: participant.id,
        message: isEmail ? emailInvitationText : smsInvitationText,
        ownMessage: "",
        savedMessageId: isEmail ? emailSelectedMessageId : smsSelectedMessageId,
        isEmailOrSms: isEmail,
        languageId: languageId,
        isEmailOrSmsReminder: isReminder,
        phoneNumber: participant.phoneNumber ?? "",
      });
    });

    sendParticipantsInvitation(body, languageValue, dispatch)
      .then((response: ISendParticipantsInvitationResponse[]) => {
        if (response?.length > 0) {
          setInvitationStatus({
            isEmail: isEmail,
            participants: response,
          });
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  const handleSendClick = (isEmail: boolean): void => {
    const participantWithoutPhNo = props.participants.filter(
      (participant) => !participant.phoneNumber,
    );
    if (!isEmail && participantWithoutPhNo.length > 0) {
      setParticipantWithoutPhNo(participantWithoutPhNo);
    } else handleSendInvitation(isEmail);
  };

  const handlePreviewClick = (): void => {
    const languageId: number =
      props.languages.find((language) => language.name === emailLanguage)?.id ??
      0;
    let isReminder = false;
    if (
      props.participants[0].emailStatus &&
      props.participants[0].emailStatus > 1
    )
      isReminder = true;

    const body: ISendParticipantsInvitationBody = {
      id: props.participants[0].id,
      message: emailInvitationText,
      ownMessage: "",
      savedMessageId: emailSelectedMessageId,
      isEmailOrSms: true,
      languageId: languageId,
      isEmailOrSmsReminder: isReminder,
    };

    previewParticipantInvitation(body, emailLanguage, dispatch).then(
      (response) => {
        if (response) {
          setPreviewHTML(response);
        }
      },
    );
  };

  const closePreviewModal = (): void => {
    setPreviewHTML("");
  };

  const closeWarningModal = (): void => setParticipantWithoutPhNo([]);

  const handleSMSInvitation = (): void => {
    handleSendInvitation(false);
    setParticipantWithoutPhNo([]);
  };

  const getParticipantWithoutPhNo = (): JSX.Element => {
    let message = "";
    const singleParticipant = participantWithoutPhNo.length === 1;
    const multiParticipant = participantWithoutPhNo.length > 1;

    if (singleParticipant) {
      message = `The following participant doesn't have phone number`;
    } else if (multiParticipant) {
      message = `The following participants doesn't have phone numbers`;
    }

    return (
      <>
        {getLanguageValue(props.languageText, message)}
        <div className="mt-4">
          {participantWithoutPhNo.map((participant, index) => (
            <div className="ms-4 mb-2" key={index}>
              {index + 1}. {participant.name} - {participant.email}
            </div>
          ))}
        </div>
        <div className="mt-5">
          {participantWithoutPhNo.length < props.participants.length &&
            getLanguageValue(
              props.languageText,
              `Do you want to send SMS invitation except these participants`,
            )}
        </div>
      </>
    );
  };

  // Invitation Status Modal
  const closeInvitationStatusModal = (): void => {
    props.handleMessageSentSuccess(invitationStatus.isEmail);
    setInvitationStatus(initialInvitationResponseState);
  };

  const getInvitationStatus = (): JSX.Element => {
    return (
      <>
        {invitationStatus.participants.map((participant, index) => {
          return (
            <div className="mb-3" key={index}>
              {index + 1}. {participant.emailAddress} -{" "}
              {getLanguageValue(
                props.languageText,
                invitationStatus.isEmail
                  ? getEmailStatusMessage(participant.emailStatus)
                  : getSMSStatusMessage(participant.smsStatus),
              )}
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      <ModalComponent
        width="lg"
        headerText={getLanguageValue(props.languageText, "Invitation")}
        cancelButtonText={getLanguageValue(props.languageText, "Close")}
        handleCancelClick={props.handleCloseClick}
      >
        <TabNavItem
          navTabs={invitationNavTabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />

        {/* Email Invitation */}
        <TabContent id={InvitationNavTabs.email} activeTab={activeTab}>
          <EmailSMSInvitation
            isEmail={true}
            languageText={props.languageText}
            messageTranslation={props.messageTranslation}
            languages={props.languages}
            languageValue={emailLanguage}
            invitationText={emailInvitationText}
            sameInvitationForAll={true}
            invitationSent={props.emailInvitationSent}
            previewHTML={previewHTML}
            sendingInvite={spinner}
            handleLanguageSelect={handleEmailLanguageSelect}
            handleInvitationTextChange={onEmailTextChange}
            handleSelectedMessage={handleSelectedEmail}
            handleSendClick={handleSendClick}
            handlePreviewClick={handlePreviewClick}
            closePreviewModal={closePreviewModal}
          />
        </TabContent>

        {/* SMS Invitation */}
        <TabContent id={InvitationNavTabs.sms} activeTab={activeTab}>
          <EmailSMSInvitation
            isEmail={false}
            languageText={props.languageText}
            messageTranslation={props.messageTranslation}
            languages={props.languages}
            languageValue={smsLanguage}
            invitationText={smsInvitationText}
            sameInvitationForAll={true}
            invitationSent={props.smsInvitationSent}
            sendingInvite={spinner}
            handleLanguageSelect={handleSMSLanguageSelect}
            handleInvitationTextChange={onSMSTextChange}
            handleSelectedMessage={handleSelectedSMS}
            handleSendClick={handleSendClick}
          />
        </TabContent>

        {participantWithoutPhNo.length > 0 && (
          <SMSWarningModal
            headerText={getLanguageValue(props.languageText, "No Phone Number")}
            bodyText={getParticipantWithoutPhNo()}
            cancelButtonText={getLanguageValue(props.languageText, "Close")}
            submitButtonText={
              participantWithoutPhNo.length < props.participants.length
                ? getLanguageValue(props.languageText, "Yes")
                : ""
            }
            handleCancelClick={closeWarningModal}
            handleSubmitClick={handleSMSInvitation}
          />
        )}

        {invitationStatus.participants.length > 0 && (
          <InvitationStatusModal
            headerText={getLanguageValue(
              props.languageText,
              "Invitation Status",
            )}
            bodyText={getInvitationStatus()}
            submitButtonText={getLanguageValue(props.languageText, "OK")}
            handleCancelClick={closeInvitationStatusModal}
            handleSubmitClick={closeInvitationStatusModal}
          />
        )}
      </ModalComponent>
    </>
  );
};
