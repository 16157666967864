import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ColumnDef } from "@tanstack/react-table";
import { E164Number } from "libphonenumber-js/types";

import { getFormattedDateOnly } from "../../../commonUtils/dateFunctionsHelper";
import { ITabNavItem } from "../../../components/multiTabComponent/tabNavItem";
import { IFocusError } from "../../../components/formComponents/input";
import { IAddEditAccountModalProps } from "./addEditAccountModal";
import { RootState } from "@app/store/configureStore";
import { ValidationHelper } from "../../validationHelper";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import {
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import {
  createClient,
  createClientBilling,
  createClientByFacilitator,
  getAllClientSubscriptions,
  getAllCountries,
  getClientSubscriptionById,
  updateClient,
  updateClientBilling,
} from "./action";
import {
  AccountInputs,
  BillingInputs,
  BillingPeriodType,
  NavTabs,
  PaymentTermsType,
  PaymentType,
} from "./clientEnums";
import {
  IPaymentTypes,
  getPayment,
  getPaymentAfterEnd,
  getPaymentStatus,
  getPaymentTermsList,
  getPaymentTypes,
} from "../../../commonUtils/planSuscriptionHelper";
import { addToast, setSpinner } from "../../actions";
import { IClientSubscription } from "../hooks";
import { IDIInvoice } from "../../../components/invoice/invoiceInterface";
import { ICountry, ITableCommonParams } from "@app/containers/commonInterfaces";
import { isLoggedInRoleAdmin } from "@app/commonUtils/roleHelper";
import { useApiEndpoints } from "@app/api/end-points";
import { Plan, PlanType } from "@app/types";

export interface ICreateUpdateSubscriptionBody {
  id: number;
  clientId: number;
  subscriptionId: number;
  paymentTerms: number;
  price: number;
  total: number;
  billingPeriod: number;
  teamSize: number;
  startDate: string | null;
  endDate: string | null;
  invoice: boolean;
  creditCard: boolean;
  status: number | null;
  payment: number | null;
  paymentAfterEnd: number | null;
  currencyId: number | null;
}

export interface ICreateUpdateClient {
  id?: number;
  name: string;
  businessIdentification?: string;
  invoiceReference?: string;
  email?: string;
  telephone?: string;
  address?: string;
  city?: string;
  zip?: string;
  countryId: number;
  euvatId?: string;
  differentInvoiceAddress?: boolean;
  invoiceCompanyName?: string;
  invoiceAddress?: string;
  invoiceCity?: string;
  invoiceZip?: string;
  invoiceCountryId?: number;
  invoiceEUVATID?: string;
  enableParticipantCreateProfile: boolean;
  sourceType: string;
  sourceAddress: string;
}

export interface IAccountInfo {
  name: string;
  businessIdentification: string;
  invoiceReference: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  zip: string;
  countryId: string;
  euVatId: string;
  differentInvoiceAddress: boolean;
  invoiceCompanyName: string;
  invoiceAddress: string;
  invoiceCity: string;
  invoiceZip: string;
  invoiceCountryId: string;
  invoiceEUVATID: string;
  teamSize?: number;
  isSubscriptionActive?: boolean;
  numberOfSubscriptions: number;
  numberOfUsers: number;
  numberOfProfiles: number;
  numberOfProfileParticipants: number;
  enableParticipantCreateProfile: boolean;
  sourceType: string;
  sourceAddress: string;
  isDefaultClient: boolean;
}

export interface IFocusInput {
  name: IFocusError;
  email: IFocusError;
  phone: IFocusError;
  address: IFocusError;
  city: IFocusError;
  zip: IFocusError;
  countryId: IFocusError;
  invoiceCompanyName: IFocusError;
  invoiceAddress: IFocusError;
  invoiceCity: IFocusError;
  invoiceZip: IFocusError;
  invoiceCountryId: IFocusError;
}

export interface ISelectedPlan {
  selectedPlanId: number;
  price: number;
  type: PlanType;
  individual: boolean;
}

export interface IBillingInfo {
  id: number;
  subscriptionId: number;
  paymentTerms: string;
  price: string;
  billingPeriod: string;
  teamSize: string;
  startDate: Date | null;
  endDate: Date | null;
  selectedPaymentMethodId: number;
  status: string;
  payment: string;
  paymentAfterEnd: string;
  currencyId: string;
}

export interface IBillingFocusInput {
  selectedPlan: IFocusError;
  paymentTerms: IFocusError;
  price: IFocusError;
  teamSize: IFocusError;
  startDate: IFocusError;
  selectedPaymentMethodId: IFocusError;
}

const initialPlanState: Plan = {
  id: 0,
  name: "",
  type: PlanType.Plan,
  enabled: true,
  description: "",
  prices: [],
};

export const useAddAccount = (props: IAddEditAccountModalProps) => {
  const dispatch = useDispatch();
  const api = useApiEndpoints(dispatch);

  const loggedInUserRole = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserRole,
  );
  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  // Client details states
  const initialFocusInputState: IFocusError = {
    touched: false,
    errorMessage: "",
  };
  const [clientId, setClientId] = useState<number>(0);
  const [accountInfo, setAccountInfo] = useState<IAccountInfo>({
    name: "",
    businessIdentification: "",
    invoiceReference: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    zip: "",
    countryId: "",
    euVatId: "",
    differentInvoiceAddress: false,
    invoiceCompanyName: "",
    invoiceAddress: "",
    invoiceCity: "",
    invoiceZip: "",
    invoiceCountryId: "",
    invoiceEUVATID: "",
    isSubscriptionActive: true,
    numberOfSubscriptions: 0,
    numberOfUsers: 0,
    numberOfProfiles: 0,
    numberOfProfileParticipants: 0,
    enableParticipantCreateProfile: false,
    sourceType: "",
    sourceAddress: "",
    isDefaultClient: false,
  });
  const [focusInput, setFocusInput] = useState<IFocusInput>({
    name: initialFocusInputState,
    email: initialFocusInputState,
    phone: initialFocusInputState,
    address: initialFocusInputState,
    city: initialFocusInputState,
    zip: initialFocusInputState,
    countryId: initialFocusInputState,
    invoiceCompanyName: initialFocusInputState,
    invoiceAddress: initialFocusInputState,
    invoiceCity: initialFocusInputState,
    invoiceZip: initialFocusInputState,
    invoiceCountryId: initialFocusInputState,
  });
  const [countries, setCountries] = useState<IDropdownList[]>([]);
  const [activeTab, setActiveTab] = useState<string>(NavTabs.clientDetails);

  // Subscription List states
  const [data, setdata] = useState<IClientSubscription[]>([]);
  const [openClientBillingForm, setOpenClientBillingForm] =
    useState<boolean>(false); // Add/Edit Subscription
  const [clientSubscriptionId, setClientSubscriptionId] = useState<number>(0); // Edit Subscription Id

  // Subscription & Billing states
  const initialSelectedPlanState: ISelectedPlan = {
    selectedPlanId: 0,
    price: 0,
    type: PlanType.Plan,
    individual: false,
  };
  const initialBillingInfoState: IBillingInfo = {
    id: 0,
    subscriptionId: 0,
    paymentTerms: PaymentTermsType.D30.toString(),
    price: "",
    billingPeriod: "",
    teamSize: "",
    startDate: new Date(),
    endDate: null,
    selectedPaymentMethodId: 0,
    status: "",
    payment: "",
    paymentAfterEnd: "",
    currencyId: "",
  };
  const [plans, setPlans] = useState<Plan[]>([initialPlanState]);
  const [selectedPlan, setSelectedPlan] = useState<ISelectedPlan>(
    initialSelectedPlanState,
  );
  const [billingInfo, setBillingInfo] = useState<IBillingInfo>(
    initialBillingInfoState,
  );
  const [billingFocusInput, setBillingFocusInput] =
    useState<IBillingFocusInput>({
      selectedPlan: initialFocusInputState,
      paymentTerms: initialFocusInputState,
      price: initialFocusInputState,
      teamSize: initialFocusInputState,
      startDate: initialFocusInputState,
      selectedPaymentMethodId: initialFocusInputState,
    });
  const [invoiceList, setInvoiceList] = useState<IDIInvoice[]>([]);
  const [currencyList, setCurrencyList] = useState<IDropdownList[]>([]);

  // Check for edit click
  let isEditClicked: boolean = false;
  if (Object.keys(props.rowData).length > 0) isEditClicked = true;

  const navTabs = useMemo(() => {
    const tabs: ITabNavItem<NavTabs>[] = [
      {
        id: NavTabs.clientDetails,
        title: getLanguageValue(languageText, "Details"),
      },
    ];
    if (clientId) {
      tabs.push({
        id: NavTabs.billing,
        title: `${getLanguageValue(languageText, "Plan & Billing")} (${
          accountInfo.numberOfSubscriptions
        })`,
      });
    }
    if (isEditClicked) {
      if (isLoggedInRoleAdmin(loggedInUserRole)) {
        tabs.push({
          id: NavTabs.users,
          title: `${getLanguageValue(languageText, "Users")} (${accountInfo.numberOfUsers})`,
        });
      }
      tabs.push({
        id: NavTabs.profiles,
        title: `${getLanguageValue(languageText, "Profiles")} (${accountInfo.numberOfProfiles})`,
      });
    }

    return tabs;
  }, [languageText, clientId, accountInfo.numberOfUsers]);

  const paymentTermsList: IDropdownList[] = useMemo(
    () => getPaymentTermsList(languageText),
    [],
  );
  const paymentTypes: IPaymentTypes[] = useMemo(
    () => getPaymentTypes(languageText),
    [],
  );
  const paymentList: IDropdownList[] = useMemo(
    () => getPayment(languageText),
    [],
  );
  const paymentStatusList: IDropdownList[] = useMemo(
    () => getPaymentStatus(languageText),
    [],
  );
  const paymentAfterEndList: IDropdownList[] = useMemo(
    () => getPaymentAfterEnd(languageText),
    [],
  );

  const updateClientInfo = (): void => {
    setAccountInfo({
      ...accountInfo,
      name: props.rowData.name,
      businessIdentification: props.rowData.businessIdentification,
      invoiceReference: props.rowData.invoiceReference,
      email: props.rowData.email,
      phone: props.rowData.telephone,
      address: props.rowData.address,
      city: props.rowData.city,
      zip: props.rowData.zip,
      countryId: String(props.rowData.countryId),
      euVatId: props.rowData.euvatid,
      differentInvoiceAddress: props.rowData.differentInvoiceAddress,
      invoiceCompanyName: props.rowData.invoiceCompanyName,
      invoiceAddress: props.rowData.invoiceAddress,
      invoiceCity: props.rowData.invoiceCity,
      invoiceZip: props.rowData.invoiceZip,
      invoiceCountryId: String(props.rowData.invoiceCountryId),
      invoiceEUVATID: props.rowData.invoiceEUVATID,
      numberOfSubscriptions: props.rowData.numberOfSubscriptions ?? 0,
      numberOfUsers: props.rowData.numberOfUsers ?? 0,
      numberOfProfiles: props.rowData.numberOfProfiles ?? 0,
      numberOfProfileParticipants: props.rowData.numberOfProfileParticipants,
      enableParticipantCreateProfile:
        props.rowData.enableParticipantCreateProfile,
      sourceType: props.rowData.sourceType,
      sourceAddress: props.rowData.sourceAddress,
      isDefaultClient: props.rowData.isDefaultClient ? true : false,
    });
  };

  useEffect(() => {
    if (isEditClicked) {
      updateClientInfo();
      setClientId(props.rowData.id);
    }
  }, [props.rowData]);

  useEffect(() => {
    getAllCountries(dispatch).then((response: ICountry[]) => {
      if (response?.length > 0) {
        const countries: IDropdownList[] = response.map((item) => ({
          id: item.id,
          displayName: item.name,
          value: String(item.id),
        }));
        setCountries(countries);
      }
    });
  }, []);

  // Subscription useEffects
  const fetchClientSubscriptions = (): void => {
    dispatch(setSpinner(true));
    getAllClientSubscriptions("", "", 1000, 0, clientId, dispatch)
      .then((response: IClientSubscription[]) => {
        if (response) {
          setdata(response);
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  useEffect(() => {
    if (clientId) {
      fetchClientSubscriptions();
    }
  }, [clientId]);

  // Updating selected subscription plan during edit
  useEffect(() => {
    if (openClientBillingForm) {
      api.getAllCurrencies().then((response) => {
        if (response?.items.length > 0) {
          let currency: IDropdownList[] = [];
          currency = response?.items.map((item: any, index: number) => ({
            id: item.id,
            displayName: item.name,
            value: item.id.toString(),
          }));
          setCurrencyList(currency);
        }
      });
    }
  }, [openClientBillingForm]);

  useEffect(() => {
    if (openClientBillingForm) {
      const params: ITableCommonParams = {
        filter: "",
        maxResultCount: 1000,
        sorting: "",
        skipCount: 0,
      };

      api.getPlans(params).then((response) => {
        if (response) setPlans(response.items.slice());
      });
    }
  }, [openClientBillingForm]);

  useEffect(() => {
    if (plans[0]!.id! > 0 && isEditClicked && billingInfo.subscriptionId > 0) {
      const selectedPlan = plans.find(
        (subscription) => subscription.id === billingInfo.subscriptionId,
      );

      if (!selectedPlan) {
        return;
      }

      // TOOD: deal with this currency situation
      const updatedPrice = selectedPlan.prices[0]?.unitPrice;

      setSelectedPlan({
        selectedPlanId: selectedPlan.id!,
        price: updatedPrice,
        type: selectedPlan.type,
        individual: false,
      });
    }
  }, [plans, billingInfo.subscriptionId]);

  const handleFormErrors = (name: string, value: unknown): void => {
    let errorMessage: string = "";
    if (!value) {
      const errorMessages: { [key: string]: string } = {
        [AccountInputs.name]: "Company name is required",
        [AccountInputs.countryId]: "Country is required",
        [AccountInputs.invoiceCompanyName]: "Company name is required",
        [AccountInputs.invoiceCountryId]: "Country is required",
      };
      errorMessage =
        errorMessages[name] &&
        getLanguageValue(languageText, errorMessages[name]);
    }
    if (name === AccountInputs.email && typeof value === "string" && value) {
      const isValid = ValidationHelper.isEmailValid(value);
      if (!isValid)
        errorMessage = getLanguageValue(languageText, "Invalid email");
    }
    if (name === AccountInputs.phone && typeof value === "string" && value) {
      if (value.length >= 1 && value.length <= 4) {
        errorMessage = getLanguageValue(languageText, "Invalid phonenumber");
      }
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }));
  };

  const handleClientInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const name = e.target.name as keyof IFocusInput;
    const value = e.target.value;

    setAccountInfo({
      ...accountInfo,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  const handleClientCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { name, checked } = e.target;

    setAccountInfo({
      ...accountInfo,
      [name]: checked,
    });
  };

  const handleClientBlurEvent = (
    e: React.FocusEvent<HTMLInputElement>,
  ): void => {
    const name = e.target.name as keyof IFocusInput;
    const value = e.target.value;
    handleFormErrors(name, value);
  };

  const handleCountrySelect = (selectedItem: IDropdownSelectedItem): void => {
    const name = selectedItem.name as keyof IFocusInput;
    const value = selectedItem.value;

    setAccountInfo({
      ...accountInfo,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const name = e.target.name as keyof IFocusInput;
    const value = e.target.value;

    setAccountInfo({
      ...accountInfo,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  const handleTextAreaBlurEvent = (
    e: React.FocusEvent<HTMLTextAreaElement>,
  ): void => {
    const name = e.target.name as keyof IFocusInput;
    const value = e.target.value;
    handleFormErrors(name, value);
  };

  const handlePhoneInputChange = (value: E164Number): void => {
    setAccountInfo({
      ...accountInfo,
      phone: value,
    });

    if (focusInput.phone?.touched) {
      handleFormErrors(AccountInputs.phone, value);
    }
  };

  const handleDifferentInvoiceAddress = (): void => {
    if (accountInfo.differentInvoiceAddress) {
      setAccountInfo({
        ...accountInfo,
        differentInvoiceAddress: false,
      });
    } else {
      setAccountInfo({
        ...accountInfo,
        differentInvoiceAddress: true,
        invoiceAddress: "",
        invoiceZip: "",
        invoiceCity: "",
        invoiceCountryId: "",
      });
    }
  };

  const handleSubscription = (isActive: boolean) => {
    setAccountInfo({
      ...accountInfo,
      isSubscriptionActive: isActive,
    });
  };

  const closeAddEditAccountModal = (): void => {
    props.closeClientModal();
  };

  const handleClientValidationOnSubmit = (): boolean => {
    if (accountInfo.differentInvoiceAddress) {
      if (
        !accountInfo.name ||
        focusInput.name.errorMessage ||
        !accountInfo.countryId ||
        focusInput.countryId.errorMessage ||
        !accountInfo.invoiceCompanyName ||
        focusInput.invoiceCompanyName.errorMessage ||
        !accountInfo.invoiceCountryId ||
        focusInput.invoiceCountryId.errorMessage
      ) {
        for (const item of Object.values(AccountInputs).slice(0, 4)) {
          handleFormErrors(item, accountInfo[item]);
        }
        return false;
      }
    } else {
      if (
        !accountInfo.name ||
        focusInput.name.errorMessage ||
        !accountInfo.countryId ||
        focusInput.countryId.errorMessage
      ) {
        for (const item of Object.values(AccountInputs).slice(0, 2)) {
          handleFormErrors(item, accountInfo[item]);
        }
        return false;
      }
    }

    return true;
  };

  const handleClientSubmit = async (): Promise<void> => {
    if (!handleClientValidationOnSubmit()) return;

    dispatch(setSpinner(true));
    let responseSuccess: boolean = false;

    const body: ICreateUpdateClient = {
      id: clientId ?? 0,
      name: accountInfo.name,
      businessIdentification: accountInfo.businessIdentification,
      invoiceReference: accountInfo.invoiceReference,
      email: accountInfo.email,
      telephone: accountInfo.phone,
      address: accountInfo.address,
      city: accountInfo.city,
      zip: accountInfo.zip,
      countryId: Number(accountInfo.countryId),
      euvatId: accountInfo.euVatId,
      differentInvoiceAddress: accountInfo.differentInvoiceAddress,
      invoiceCompanyName: accountInfo.invoiceCompanyName,
      invoiceAddress: accountInfo.invoiceAddress,
      invoiceCity: accountInfo.invoiceCity,
      invoiceZip: accountInfo.invoiceZip,
      invoiceCountryId: Number(accountInfo.invoiceCountryId),
      invoiceEUVATID: accountInfo.invoiceEUVATID,
      enableParticipantCreateProfile:
        accountInfo.enableParticipantCreateProfile,
      sourceType: accountInfo.sourceType,
      sourceAddress: accountInfo.sourceAddress,
    };

    if (clientId) {
      await updateClient(body, dispatch).then((response) => {
        if (response?.success) {
          dispatch(addToast("Changes saved successfully") as AnyAction);
          responseSuccess = true;
        }
      });
    } else if (!isLoggedInRoleAdmin(loggedInUserRole)) {
      await createClientByFacilitator(body, dispatch).then((response) => {
        if (response?.success) {
          setClientId(response.result);
          dispatch(addToast("Client created successfully") as AnyAction);
          responseSuccess = true;
          if (props.isActivity) {
            props.closeClientModal(response.result);
          }
        }
      });
    } else {
      await createClient(body, dispatch).then((response) => {
        if (response?.success) {
          setClientId(response.result);
          dispatch(addToast("Client created successfully") as AnyAction);
          responseSuccess = true;
          if (props.isActivity) {
            props.closeClientModal();
          }
        }
      });
    }

    if (responseSuccess) {
      props.refreshClientList();
      setActiveTab(NavTabs.billing);
    }
    dispatch(setSpinner(false));
  };

  // Subsciption & Billing functions
  const addSubscriptionClick = (): void => {
    setOpenClientBillingForm(true);
  };

  const handleBackToSubscriptionList = (): void => {
    setOpenClientBillingForm(false);
    setClientSubscriptionId(0);
    setSelectedPlan(initialSelectedPlanState);
    setBillingInfo(initialBillingInfoState);
    setBillingFocusInput({
      selectedPlan: initialFocusInputState,
      paymentTerms: initialFocusInputState,
      price: initialFocusInputState,
      teamSize: initialFocusInputState,
      startDate: initialFocusInputState,
      selectedPaymentMethodId: initialFocusInputState,
    });
    setInvoiceList([]);
  };

  const getClientSubscription = (clientSubscriptionId: number): void => {
    getClientSubscriptionById(clientSubscriptionId, dispatch).then(
      (response: IClientSubscription) => {
        if (response) {
          setOpenClientBillingForm(true);
          setClientSubscriptionId(clientSubscriptionId);

          const clientSubscription = response;
          setBillingInfo({
            ...billingInfo,
            id: clientSubscription.id,
            subscriptionId: clientSubscription.subscriptionId ?? 0,
            paymentTerms: clientSubscription.paymentTerms.toString(),
            price: clientSubscription.price.toString(),
            billingPeriod: clientSubscription.billingPeriod.toString(),
            teamSize: clientSubscription.teamSize.toString(),
            startDate: clientSubscription.startDate
              ? new Date(clientSubscription.startDate)
              : null,
            endDate: clientSubscription.endDate
              ? new Date(clientSubscription.endDate)
              : null,
            selectedPaymentMethodId: clientSubscription.invoice
              ? PaymentType.Invoice
              : PaymentType.CreditCard,
            status:
              clientSubscription.status !== null
                ? clientSubscription.status.toString()
                : initialBillingInfoState.status,
            payment:
              clientSubscription.payment !== null
                ? clientSubscription.payment.toString()
                : initialBillingInfoState.payment,
            paymentAfterEnd:
              clientSubscription.paymentAfterEnd !== null
                ? clientSubscription.paymentAfterEnd.toString()
                : initialBillingInfoState.paymentAfterEnd,
            currencyId:
              clientSubscription.currencyId !== null
                ? clientSubscription.currencyId.toString()
                : initialBillingInfoState.currencyId,
          });

          let updatedInvoiceList: IDIInvoice[] = [];
          if (clientSubscription.idiInvoices?.length > 0) {
            updatedInvoiceList = clientSubscription.idiInvoices;
          }
          setInvoiceList(updatedInvoiceList);
        }
      },
    );
  };

  const handleEditSubscriptionClick = (id: number): void => {
    getClientSubscription(id);
  };

  const subscriptionTableHeader = useMemo<
    ColumnDef<IClientSubscription, unknown>[]
  >(
    () => [
      {
        header: "",
        accessorKey: "actions",
        enableSorting: false,
        cell: ({ ...props }) => (
          <a
            className="btn btn-lg border-end rounded-0 my-n3 ms-n3"
            onClick={() => handleEditSubscriptionClick(props.row.original.id)}
          >
            <i className="bi bi-pencil-fill text-secondary" />
          </a>
        ),
      },
      {
        header: getLanguageValue(languageText, "Plan Name"),
        accessorKey: "planName",
        enableSorting: false,
      },
      {
        header: getLanguageValue(languageText, "Billing Type"),
        accessorKey: "billingPeriod",
        enableSorting: false,
        cell: ({ ...props }) => (
          <>
            {getLanguageValue(
              languageText,
              BillingPeriodType[props.row.original.billingPeriod],
            )}
          </>
        ),
      },
      {
        header: getLanguageValue(languageText, "Price"),
        accessorKey: "price",
        enableSorting: false,
      },
      {
        header: getLanguageValue(languageText, "Team Size"),
        accessorKey: "teamSize",
        enableSorting: false,
        cell: ({ ...props }) => (
          <>{props.row.original.teamSize ? props.row.original.teamSize : "-"}</>
        ),
      },
      {
        header: getLanguageValue(languageText, "Total Price"),
        accessorKey: "total",
        enableSorting: false,
        cell: ({ ...props }) => (
          <>{props.row.original.total ? props.row.original.total : "-"}</>
        ),
      },
    ],
    [languageText],
  );

  const handlePlanChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked } = e.target;
    setBillingInfo({
      ...billingInfo,
      price: "",
      teamSize: "",
    });
    setSelectedPlan(initialSelectedPlanState);
  };

  const handleBillingFormErrors = (name: string, value: unknown): void => {
    let errorMessage: string = "";
    if (!value || value === null) {
      const errorMessages: { [key: string]: string } = {
        [BillingInputs.selectedPlan]: "Select any plan",
        [BillingInputs.paymentTerms]: "Select payment terms",
        [BillingInputs.price]: "Price is required",
        [BillingInputs.teamSize]: "Team size is required",
        [BillingInputs.startDate]: "Start date is required",
        [BillingInputs.selectedPaymentMethodId]: "Select payment method",
      };
      errorMessage =
        errorMessages[name] &&
        getLanguageValue(languageText, errorMessages[name]);
    }
    if (value && name === BillingInputs.teamSize && Number(value) < 2) {
      errorMessage = getLanguageValue(
        languageText,
        "Minimum a team of 2 is required",
      );
    }

    setBillingFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }));
  };

  const handleSelectPlan = (
    selectedPlanId: number,
    price: number,
    type: number,
    individual: boolean,
  ) => {
    if (selectedPlanId === selectedPlan.selectedPlanId) return;
    setSelectedPlan({
      selectedPlanId,
      price,
      type,
      individual,
    });
    setBillingInfo({
      ...billingInfo,
      price: price.toString(),
      teamSize:
        type === PlanType.Plan || individual ? "" : billingInfo.teamSize,
    });
    handleBillingFormErrors(BillingInputs.selectedPlan, selectedPlanId);
    if (billingFocusInput.price.errorMessage) {
      handleBillingFormErrors(BillingInputs.price, price);
    }
  };

  const handleBillingInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const name = e.target.name as keyof IBillingFocusInput;
    const value = e.target.value;

    setBillingInfo({
      ...billingInfo,
      [name]: value,
    });

    if (billingFocusInput[name]?.touched) {
      handleBillingFormErrors(name, value);
    }
  };

  const handleBillingBlurEvent = (
    e: React.FocusEvent<HTMLInputElement>,
  ): void => {
    const name = e.target.name as keyof IFocusInput;
    const value = e.target.value;
    handleBillingFormErrors(name, value);
  };

  const handleBillingDropdownSelect = (
    selectedItem: IDropdownSelectedItem,
  ): void => {
    const name = selectedItem.name as keyof IBillingFocusInput;
    const value = selectedItem.value;

    setBillingInfo({
      ...billingInfo,
      [name]: value,
    });

    if (billingFocusInput[name]?.touched) {
      handleBillingFormErrors(name, value);
    }
  };

  const handleDateSelect = (name: string, date: Date): void => {
    if (
      name === BillingInputs.endDate &&
      billingInfo.startDate &&
      date < billingInfo.startDate
    ) {
      return;
    }
    if (
      name === BillingInputs.startDate &&
      billingInfo.endDate &&
      date > billingInfo.endDate
    ) {
      setBillingInfo({
        ...billingInfo,
        startDate: date,
        endDate: date,
      });
    } else {
      setBillingInfo({
        ...billingInfo,
        [name]: date,
      });
    }
    if (name === BillingInputs.startDate) {
      handleBillingFormErrors(
        BillingInputs.startDate,
        getFormattedDateOnly(date),
      );
    }
  };

  const handleSelectPaymentMethod = (paymentId: number) => {
    setBillingInfo({
      ...billingInfo,
      selectedPaymentMethodId: paymentId,
    });
    handleBillingFormErrors(BillingInputs.selectedPaymentMethodId, paymentId);
  };

  const handleBillingValidationOnSubmit = (): boolean => {
    if (
      !selectedPlan.selectedPlanId ||
      !billingInfo.selectedPaymentMethodId ||
      !billingInfo.startDate ||
      (isLoggedInRoleAdmin(loggedInUserRole) &&
        (!billingInfo.paymentTerms || !billingInfo.price)) ||
      (selectedPlan.type !== PlanType.Plan &&
        !selectedPlan.individual &&
        (!billingInfo.teamSize || Number(billingInfo.teamSize) < 2))
    ) {
      for (const item of Object.values(BillingInputs)) {
        if (item === BillingInputs.selectedPlan) {
          handleBillingFormErrors(
            BillingInputs.selectedPlan,
            selectedPlan.selectedPlanId,
          );
          break;
        } else handleBillingFormErrors(item, billingInfo[item]);
      }
      return false;
    }
    return true;
  };

  const handleBillingSubmit = (): void => {
    if (!handleBillingValidationOnSubmit()) return;
    dispatch(setSpinner(true));

    let totalPrice = 0;
    if (selectedPlan.individual) {
      totalPrice = Number(billingInfo.price);
    } else if (selectedPlan.type === PlanType.Subscription) {
      totalPrice = Number(billingInfo.price) * Number(billingInfo.teamSize);
    }

    const startDate: string | null = billingInfo.startDate
      ? getFormattedDateOnly(billingInfo.startDate)
      : null;
    const endDate: string | null = billingInfo.endDate
      ? getFormattedDateOnly(billingInfo.endDate)
      : null;

    const body: ICreateUpdateSubscriptionBody = {
      id: billingInfo.id ?? 0,
      clientId,
      subscriptionId: selectedPlan.selectedPlanId,
      paymentTerms: Number(billingInfo.paymentTerms),
      price: Number(billingInfo.price),
      total: totalPrice,
      billingPeriod: BillingPeriodType.Annual,
      teamSize: selectedPlan.individual ? 1 : Number(billingInfo.teamSize),
      startDate,
      endDate,
      invoice: billingInfo.selectedPaymentMethodId === PaymentType.Invoice,
      creditCard:
        billingInfo.selectedPaymentMethodId === PaymentType.CreditCard,
      payment:
        billingInfo.payment !== initialBillingInfoState.payment
          ? Number(billingInfo.payment)
          : null,
      status:
        billingInfo.status !== initialBillingInfoState.status
          ? Number(billingInfo.status)
          : null,
      paymentAfterEnd:
        billingInfo.paymentAfterEnd !== initialBillingInfoState.paymentAfterEnd
          ? Number(billingInfo.paymentAfterEnd)
          : null,
      currencyId:
        billingInfo.currencyId !== initialBillingInfoState.currencyId
          ? Number(billingInfo.status)
          : null,
    };

    if (!body.id) {
      createClientBilling(body, dispatch)
        .then((response) => {
          if (response?.success) {
            dispatch(addToast("Created billing successfully") as AnyAction);
            handleBackToSubscriptionList();
            fetchClientSubscriptions();
            props.refreshClientList();
          }
        })
        .finally(() => {
          dispatch(setSpinner(false));
        });
    } else {
      updateClientBilling(body, dispatch)
        .then((response) => {
          if (response?.success) {
            dispatch(addToast("Updated billing successfully") as AnyAction);
            handleBackToSubscriptionList();
            fetchClientSubscriptions();
            props.refreshClientList();
          }
        })
        .finally(() => {
          dispatch(setSpinner(false));
        });
    }
  };

  return {
    languageText,
    clientId,
    isEditClicked,
    navTabs,
    activeTab,
    accountInfo,
    focusInput,
    countries,
    subscriptionTableHeader,
    data,
    openClientBillingForm,
    clientSubscriptionId,
    plans,
    selectedPlan,
    paymentTermsList,
    billingInfo,
    billingFocusInput,
    paymentTypes,
    paymentStatusList,
    paymentList,
    paymentAfterEndList,
    currencyList,
    invoiceList,
    setActiveTab,
    handleClientInputChange,
    handleClientCheckboxChange,
    handleClientBlurEvent,
    handleCountrySelect,
    handleTextAreaChange,
    handleTextAreaBlurEvent,
    handlePhoneInputChange,
    handleDifferentInvoiceAddress,
    handleSubscription,
    getClientSubscription,
    closeAddEditAccountModal,
    handleClientSubmit,
    addSubscriptionClick,
    handleBackToSubscriptionList,
    handleSelectPlan,
    handlePlanChange,
    handleBillingInputChange,
    handleBillingBlurEvent,
    handleBillingDropdownSelect,
    handleDateSelect,
    handleSelectPaymentMethod,
    handleBillingSubmit,
  };
};
