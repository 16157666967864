import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@app/store/configureStore";
import { ITranslationObject } from "../../../commonUtils/languageFunctionsHelper";
import {
  getActivityById,
  getActivityByStatus,
  sendPublishOrManualProfileDeliverEmail,
} from "./actions";
import { getFacilitatorCultures } from "../../languageList/actions";
import { IRoleStepData } from "../addActivity/activityRoleStep";
import {
  IEditActivity,
  IEditActivityCompletedProfile,
  IEditActivityDeliveredProfile,
  IEditProfileNewParticipantStepBody,
  IEditActivityNewProfile,
  IEditActivityOngoingProfile,
  IEditActivityPlannedPresentation,
} from "./interface";
import {
  CoursePresentationStatus,
  ActivityStatus,
  ProfileStatus,
} from "../activityEnums";
import {
  addToast,
  getLanguageTextByName,
  markGuideAsCompleted,
  setSpinner,
} from "../../actions";
import { getParticipantLanguages } from "@app/containers/languageList/languageTexts/actions";
import { ILanguage } from "@app/containers/commonInterfaces";
import { AnyAction } from "redux";
import { isLoggedInRoleAdmin } from "@app/commonUtils/roleHelper";
import { GuidePages } from "@app/containers/commonEnums";
import { ActivityId, ProfileId, UserId } from "@app/containers/reducer";

export const useEditActivity = () => {
  const dispatch = useDispatch();
  const id: ActivityId = (Number(useParams().id) || 0) as ActivityId;
  const location = useLocation();
  // This clientId is when we come from account page - profile list. This is required for navigating back when profile is deleted.
  const clientIdFromAccount = location.state ? Number(location.state) : 0;

  const loggedInUserRole = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserRole,
  );
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const guideData = useSelector(
    (state: RootState) => state.mainReducer.guideData,
  );

  const initialNewParticipantState: IEditActivityNewProfile[] = [
    {
      userId: 0 as UserId,
      name: "",
      emailAddress: "",
      phoneNumber: "",
      participantLink: "",
      emailStatus: 0,
      smsStatus: 0,
      roleId: 0,
      emailOwnMessage: "",
      smsOwnMessage: "",
      roleText: "",
      noOfRespondents: 0,
      isRoleSet: true,
      id: 0 as ProfileId,
    },
  ];
  const initialOngoingParticipantState: IEditActivityOngoingProfile[] = [
    {
      userId: 0 as UserId,
      name: "",
      emailAddress: "",
      phoneNumber: "",
      respondentsInvited: 0,
      respondentsAnswered: 0,
      noOfRespondents: 0,
      emailStatus: 0,
      smsStatus: 0,
      emailOwnMessage: "",
      smsOwnMessage: "",
      roleId: 0,
      roleText: "",
      isRoleSet: true,
      id: 0 as ProfileId,
    },
  ];
  const initialCompletedParticipantState: IEditActivityCompletedProfile[] = [
    {
      presentationId: 0,
      userId: 0 as UserId,
      name: "",
      emailAddress: "",
      respondentsInvited: 0,
      respondentsAnswered: 0,
      noOfRespondents: 0,
      roleId: 0,
      roleText: "",
      isRoleSet: true,
      presentationDate: "",
      id: 0 as ProfileId,
      pdfProfileDownloaded: false,
    },
  ];
  const initialDeliveredParticipantState: IEditActivityDeliveredProfile[] = [
    {
      userId: 0 as UserId,
      name: "",
      emailAddress: "",
      respondentsInvited: 0,
      respondentsAnswered: 0,
      noOfRespondents: 0,
      roleId: 0,
      roleText: "",
      presentation: 0,
      presentationId: 0,
      isRoleSet: true,
      presentationDate: "",
      id: 0 as ProfileId,
    },
  ];
  const initialPresentationState: IEditActivityPlannedPresentation[] = [
    {
      presentationDateTime: "",
      presentationStatus: CoursePresentationStatus.Unknown,
      presentationTemplateName: "",
      presentationLanguageFlag: "",
      presentationId: 0,
      presentationTemplateId: 0,
      currentSlideId: 0,
      presentationPublicId: "",
    },
  ];
  const initialProfileDataState: IEditActivity = {
    id: 0 as ActivityId,
    status: ActivityStatus.Unknown,
    clientId: 0,
    facilitatorId: 0 as UserId,
    clientName: "",
    completionDate: "",
    activityType: true,
    notes: "",
    facilitator: "",
    name: "",
    idiLanguageId: 0,
    countryId: 0,
    roleSetBy: 0,
    roleSameForAll: true,
    roleid: 0,
    roleText: "",
    roleNoOfRespondents: 0,
    roleCanBeChangedByParticipant: false,
    participantInviteExternal: false,
    participantInviteOtherParticipant: false,
    participantInviteColleagues: false,
    profileCount: 0,
    newProfileCount: 0,
    newProfiles: initialNewParticipantState,
    onGoingProfileCount: 0,
    onGoingProfiles: initialOngoingParticipantState,
    completedProfileCount: 0,
    completedProfiles: initialCompletedParticipantState,
    plannedPresentations: initialPresentationState,
    deliveredProfileCount: 0,
    deliveredProfiles: initialDeliveredParticipantState,
    sourceType: "",
    sourceAddress: "",
    isProBono: false,
  };
  const [profileData, setProfileData] = useState<IEditActivity>(
    initialProfileDataState,
  );
  const [cultures, setCultures] = useState<ReadonlyArray<ILanguage>>([]);
  const [languages, setLanguages] = useState<ReadonlyArray<ILanguage>>([]); // Participant languages
  const [cultureValue, setCultureValue] = useState<string>("");
  const [showProfileBillingModal, setShowProfileBillingModal] =
    useState<boolean>(false);
  const [isActivityCancelled, setIsActivityCancelled] =
    useState<boolean>(false); // Used only for facilitator
  const [isActivityInvoiced, setIsActivityInvoiced] = useState<boolean>(false); // Used only for facilitator

  // Participant Step
  const [messageTranslation, setMessageTranslation] =
    useState<ITranslationObject>({} as ITranslationObject);
  const [refetchProfile, setRefetchProfile] = useState<boolean>(false);
  const [refetchNewParticipants, setRefetchNewParticipants] =
    useState<boolean>(false);
  const [refetchOngoingParticipants, setRefetchOngoingParticipants] =
    useState<boolean>(false);
  const [refetchCompletedParticipants, setRefetchCompletedParticipants] =
    useState<boolean>(false);
  const [refetchDeliveredParticipants, setRefetchDeliveredParticipants] =
    useState<boolean>(false);
  const [refetchPlannedPresentations, setRefetchPlannedPresentations] =
    useState<boolean>(false);
  const [deliverEmailProfileIds, setDeliverEmailProfileIds] = useState<
    Array<ProfileId>
  >([]);

  // States required for guide
  const [editProfileGuideDone, setEditProfileGuideDone] =
    useState<boolean>(false);
  const [newStatusEditProfileGuideDone, setNewStatusEditProfileGuideDone] =
    useState<boolean>(false);
  const [
    onGoingStatusEditProfileGuideDone,
    setOnGoingStatusEditProfileGuideDone,
  ] = useState<boolean>(false);
  const [
    completedStatusEditProfileGuideDone,
    setCompletedStatusEditProfileGuideDone,
  ] = useState<boolean>(false);
  const [isInstructionHelpClicked, setIsInstructionHelpClicked] =
    useState<boolean>(false);

  const getCultures = async (): Promise<void> => {
    await getFacilitatorCultures(dispatch).then((response) => {
      if (response?.length > 0) {
        setCultures(response);
      }
    });
  };

  const fetchParticipantLanguages = (): void => {
    getParticipantLanguages(dispatch).then((response) => {
      if (response && response.length > 0) {
        setLanguages(response);
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Required when coming directly from add-profile page
    getCultures();
    fetchParticipantLanguages();
  }, []);

  useEffect(() => {
    if (id && loggedInUserRole) {
      dispatch(setSpinner(true));
      getActivityById(id, dispatch)
        .then((response) => {
          if (response?.success) {
            const data: IEditActivity = response.result;
            setProfileData(data);
            if (
              !isLoggedInRoleAdmin(loggedInUserRole) &&
              data.status === ActivityStatus.Cancelled
            ) {
              setIsActivityCancelled(true);
            }
            if (
              !isLoggedInRoleAdmin(loggedInUserRole) &&
              data.status === ActivityStatus.Invoiced
            ) {
              setIsActivityInvoiced(true);
            }
          }
        })
        .finally(() => dispatch(setSpinner(false)));
    }
  }, [id, refetchProfile, loggedInUserRole]);

  useEffect(() => {
    if (id && profileData.idiLanguageId && cultures.length > 0) {
      const cultureValue =
        cultures.find((culture) => culture.id === profileData.idiLanguageId)
          ?.name ?? "";
      setCultureValue(cultureValue);
    }
  }, [id, profileData.idiLanguageId, cultures]);

  const getMessageTranslations = (): void => {
    const languageCode: string =
      cultures.find((culture) => culture.id === profileData.idiLanguageId)
        ?.name ?? "";

    getLanguageTextByName(languageCode, dispatch).then((lang) => {
      setMessageTranslation(lang);
    });
  };

  useEffect(() => {
    if (profileData.idiLanguageId && cultures.length > 0) {
      getMessageTranslations();
    }
  }, [profileData.idiLanguageId, cultures]);

  // Participant Steps re-render
  useEffect(() => {
    if (
      refetchNewParticipants ||
      refetchOngoingParticipants ||
      refetchCompletedParticipants ||
      refetchDeliveredParticipants ||
      refetchPlannedPresentations
    ) {
      let countKey: keyof IEditActivity | undefined;
      let participantsKey: keyof IEditActivity;
      const body: IEditProfileNewParticipantStepBody = {
        activityId: id,
        status: ProfileStatus.New,
      };

      switch (true) {
        case refetchNewParticipants:
          body.status = ProfileStatus.New;
          countKey = "newProfileCount";
          participantsKey = "newProfiles";
          break;
        case refetchOngoingParticipants:
          body.status = ProfileStatus.Active;
          countKey = "onGoingProfileCount";
          participantsKey = "onGoingProfiles";
          break;
        case refetchCompletedParticipants:
          body.status = ProfileStatus.Completed;
          countKey = "completedProfileCount";
          participantsKey = "completedProfiles";
          break;
        case refetchDeliveredParticipants:
          body.status = ProfileStatus.Delivery;
          countKey = "deliveredProfileCount";
          participantsKey = "deliveredProfiles";
          break;
        case refetchPlannedPresentations:
          body.status = ProfileStatus.DeliveryPlanned;
          participantsKey = "plannedPresentations";
          break;
        default:
          break;
      }

      getActivityByStatus(body, dispatch).then((response) => {
        if (response?.success) {
          const updatedData = response.result;
          setProfileData((prev) => {
            const next: IEditActivity = {
              ...prev,
              [participantsKey]: updatedData[participantsKey],
            };
            if (countKey) {
              (next as any)[countKey] = updatedData[countKey];
            }
            return next;
          });
          if (refetchNewParticipants) setRefetchNewParticipants(false);
          else if (refetchOngoingParticipants)
            setRefetchOngoingParticipants(false);
          else if (refetchCompletedParticipants)
            setRefetchCompletedParticipants(false);
          else if (refetchDeliveredParticipants)
            setRefetchDeliveredParticipants(false);
          else if (refetchPlannedPresentations)
            setRefetchPlannedPresentations(false);
        }
      });
    }
  }, [
    refetchNewParticipants,
    refetchOngoingParticipants,
    refetchCompletedParticipants,
    refetchDeliveredParticipants,
    refetchPlannedPresentations,
  ]);

  const handleProfileCancel = (): void => setIsActivityCancelled(true);

  const postGuide = (guidePage: GuidePages): void => {
    const guide = guideData?.find((g) => g.guideName === guidePage);
    if (!guide?.isCompleted) {
      markGuideAsCompleted(guidePage, dispatch);
    }
  };

  const resetAllAutoTriggeredGuideStates = (): void => {
    // // FIXME: remove local state
    setEditProfileGuideDone(false);
    setNewStatusEditProfileGuideDone(false);
    setOnGoingStatusEditProfileGuideDone(false);
    setCompletedStatusEditProfileGuideDone(false);
  };

  const skipAllEditProfileGuides = () => {
    if (profileData.newProfileCount > 0)
      postGuide(GuidePages.Edit_Profile_New_Status);
    if (profileData.onGoingProfileCount > 0)
      postGuide(GuidePages.Edit_Profile_OnGoing_Status);
    if (profileData.completedProfileCount > 0)
      postGuide(GuidePages.Edit_Profile_Completed_Status);
    if (profileData.deliveredProfileCount > 0)
      postGuide(GuidePages.Edit_Profile_Delivered_Status);
    resetAllAutoTriggeredGuideStates();
  };

  const handleExitGuide = (guidePage: GuidePages, isSkipped: boolean): void => {
    // what.. even.. is.. this?
    //
    // the guides surely need to be more declarative than this, no? we should
    // for example be able to declare inside a guide that it depends on some
    // other guide (or whatever behavior it needs).
    //
    // this seems like it's just begging to break - we even have state variables
    // controlling which guide to trigger! is that really necessary? couldn't
    // those be derived from 'profileData' directly on page load?
    //
    //   -johan, 2024-09-22

    if (isSkipped) {
      if (guidePage === GuidePages.Edit_Profile) {
        postGuide(GuidePages.Edit_Profile);
      }
      skipAllEditProfileGuides();
    } else {
      switch (guidePage) {
        case GuidePages.Edit_Profile:
          postGuide(GuidePages.Edit_Profile);
          if (profileData.newProfileCount > 0) {
            // FIXME: remove local state
            setEditProfileGuideDone(true);
          } else if (profileData.onGoingProfileCount > 0) {
            // FIXME: remove local state
            setNewStatusEditProfileGuideDone(true);
          } else if (profileData.completedProfileCount > 0) {
            // FIXME: remove local state
            setOnGoingStatusEditProfileGuideDone(true);
          } else if (profileData.deliveredProfileCount > 0) {
            // FIXME: remove local state
            setCompletedStatusEditProfileGuideDone(true);
          }
          break;
        case GuidePages.Edit_Profile_New_Status:
          postGuide(GuidePages.Edit_Profile_New_Status);

          // FIXME: remove local state
          setEditProfileGuideDone(false);
          if (profileData.onGoingProfileCount > 0) {
            // FIXME: remove local state
            setNewStatusEditProfileGuideDone(true);
          } else if (profileData.completedProfileCount > 0) {
            // FIXME: remove local state
            setOnGoingStatusEditProfileGuideDone(true);
          } else if (profileData.deliveredProfileCount > 0) {
            // FIXME: remove local state
            setCompletedStatusEditProfileGuideDone(true);
          }
          break;
        case GuidePages.Edit_Profile_OnGoing_Status:
          postGuide(GuidePages.Edit_Profile_OnGoing_Status);

          // FIXME: remove local state
          setNewStatusEditProfileGuideDone(false);
          if (profileData.completedProfileCount > 0) {
            // FIXME: remove local state
            setOnGoingStatusEditProfileGuideDone(true);
          } else if (profileData.deliveredProfileCount > 0) {
            // FIXME: remove local state
            setCompletedStatusEditProfileGuideDone(true);
          }
          break;
        case GuidePages.Edit_Profile_Completed_Status:
          postGuide(GuidePages.Edit_Profile_Completed_Status);

          // FIXME: remove local state
          setOnGoingStatusEditProfileGuideDone(false);
          if (profileData.deliveredProfileCount > 0) {
            // FIXME: remove local state
            setCompletedStatusEditProfileGuideDone(true);
          }
          break;
        case GuidePages.Edit_Profile_Delivered_Status:
          postGuide(GuidePages.Edit_Profile_Delivered_Status);
          resetAllAutoTriggeredGuideStates();
          break;
        default:
          break;
      }
    }
  };

  const handleInstructionHelpClick = (): void => {
    setIsInstructionHelpClicked(true);
  };

  const handleClientSelect = (
    clientId: number,
    clientName: string,
    isFacilitatorRemoved: boolean,
  ): void => {
    if (isFacilitatorRemoved) {
      setProfileData({
        ...profileData,
        clientId: clientId,
        clientName: clientName,
        facilitatorId: 0 as UserId,
        facilitator: "",
      });
    } else {
      setProfileData({
        ...profileData,
        clientId: clientId,
        clientName: clientName,
      });
    }
  };

  const handleLanguageSelect = (languageId: number): void => {
    setProfileData((prev) => ({
      ...prev,
      idiLanguageId: languageId,
    }));
  };

  const handleRoleChanges = (roleSettings: IRoleStepData): void => {
    setProfileData((prev) => ({
      ...prev,
      roleSetBy: roleSettings.roleSetBy,
      roleSameForAll: roleSettings.roleSameForAll,
      roleid: roleSettings.roleId,
      roleText: roleSettings.roleText,
      roleNoOfRespondents: roleSettings.noOfRespondents,
      roleCanBeChangedByParticipant: roleSettings.roleCanBeChangedByParticipant,
    }));
  };

  const refetchFullProfile = (): void => setRefetchProfile(!refetchProfile);

  const triggerGuideOnRefetchingParticipants = (
    guideName: GuidePages,
  ): boolean => {
    const guide = guideData?.find((g) => g.guideName === guideName);
    if (!guide?.isCompleted) {
      return true;
    }
    return false;
  };

  const refetchNewParticipantsStep = (): void => {
    setRefetchNewParticipants(true);
    if (
      triggerGuideOnRefetchingParticipants(GuidePages.Edit_Profile_New_Status)
    ) {
      setEditProfileGuideDone(true);
    }
  };

  const refetchOngoingParticipantsStep = (): void =>
    setRefetchOngoingParticipants(true);

  const refetchCompletedParticipantsStep = (): void => {
    setRefetchCompletedParticipants(true);
    if (
      triggerGuideOnRefetchingParticipants(
        GuidePages.Edit_Profile_Completed_Status,
      )
    ) {
      setOnGoingStatusEditProfileGuideDone(true);
    }
  };

  const refetchDeliveredParticipantsStep = (): void => {
    setRefetchDeliveredParticipants(true);
    if (
      triggerGuideOnRefetchingParticipants(
        GuidePages.Edit_Profile_Delivered_Status,
      )
    ) {
      setCompletedStatusEditProfileGuideDone(true);
    }
  };

  const refetchPlannedPresentationsStep = (): void =>
    setRefetchPlannedPresentations(true);

  const handleCancelEmailConfirmation = (): void => {
    setDeliverEmailProfileIds([]);
  };

  const handleSendEmailConfirmation = (): void => {
    sendPublishOrManualProfileDeliverEmail(
      id,
      cultureValue,
      deliverEmailProfileIds,
      dispatch,
    ).then((response) => {
      if (response) {
        dispatch(addToast("Email sent successfully") as AnyAction);
        handleCancelEmailConfirmation();
      }
    });
  };

  const sendEmailForDeliveredProfiles = (
    profileIds: Array<ProfileId>,
  ): void => {
    setDeliverEmailProfileIds(profileIds);
  };

  const handlePublishProfile = (profileId: ProfileId): void => {
    refetchFullProfile();

    refetchCompletedParticipantsStep();
    refetchDeliveredParticipantsStep();

    sendEmailForDeliveredProfiles([profileId]);
  };

  const handleUnPublishProfile = (): void => {
    refetchFullProfile();

    refetchDeliveredParticipantsStep();
    refetchCompletedParticipantsStep();
  };

  // activity billing modal
  const onBillingButtonClick = (): void => {
    setShowProfileBillingModal(true);
  };

  const onHandleClose = (): void => {
    setShowProfileBillingModal(false);
  };

  const handleTransferProfile = (): void => {
    refetchFullProfile();
  };

  return {
    id,
    languageText,
    isInstructionHelpClicked,
    editProfileGuideDone,
    newStatusEditProfileGuideDone,
    onGoingStatusEditProfileGuideDone,
    completedStatusEditProfileGuideDone,
    clientIdFromAccount,
    profileData,
    cultures,
    languages,
    cultureValue,
    messageTranslation,
    isActivityCancelled,
    isActivityInvoiced,
    showProfileBillingModal,
    deliverEmailProfileIds,
    handleInstructionHelpClick,
    handleExitGuide,
    handleProfileCancel,
    handleClientSelect,
    handleLanguageSelect,
    handleRoleChanges,
    handlePublishProfile,
    handleUnPublishProfile,
    refetchFullProfile,
    refetchNewParticipantsStep,
    refetchOngoingParticipantsStep,
    refetchCompletedParticipantsStep,
    refetchDeliveredParticipantsStep,
    refetchPlannedPresentationsStep,
    onBillingButtonClick,
    onHandleClose,
    sendEmailForDeliveredProfiles,
    handleCancelEmailConfirmation,
    handleSendEmailConfirmation,
    handleTransferProfile,
  };
};
