import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { routePath } from "../routePaths";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { TermsFooter } from "../../components/termsFooter/termsFooter";
import { RoleEnum, Roles } from "../commonEnums";
import { roleAccessControl } from "./roleAccessControl";
import { ProfileList } from "../profileList";
import { EmailTemplateList } from "../emailTemplates";
import { LogEntryList } from "../log";

import { UsersList } from "../userList";
import { ClientsList } from "../clientList";
import { LanguageList } from "../languageList";
import { EmployeeList } from "../employeeList";
import { EditEmployee } from "../employeeList/editEmployee/editEmployee";
import { ActivityList } from "../activityList";
import { AddProfileForm } from "../activityList/addActivity/addProfileForm";
import { EditActivity } from "../activityList/editActivity/editProfile";
import { WordsList } from "../allWordsList";
import { PlacementList } from "../placementsList";
import { CoursesList } from "../coursesList";
import { NavigationMenu } from "../navigation/navigationMenu";
import { AlertList } from "../alertList";
import { NotFoundPage } from "../notFoundPage";
import { Account } from "../usersProfile/account/account";
import { UserProfile } from "../usersProfile";
import { PlanList } from "../planList/planList";
import { AdminDashboard } from "../adminDashboard";
import { FacilitatorDashboard } from "../facilitatorDashboard";
import { AdminNewsList } from "../resources/newsList";
import { AdminDocumentList } from "../resources/documentList";
import { Resources } from "../resources";
import { LatestNews } from "../resources/latestNews";
import { LatestNewsPage } from "../resources/latestNews/viewNews";
import { LatestNews as LatestBlogs } from "../resources/latestNews";
import { LatestNewsPage as LatestBlogPage } from "../resources/latestNews/viewNews";
import { LatestDocuments } from "../resources/documentList/latestDocuments";
import { PresentationTemplateList } from "../presentationsList/index";
import { LanguageText } from "../languageList/languageTexts/languageTexts";
import { UpcomingPresentationList } from "../presentationsList/upcoming";
import { RootState } from "@app/store/configureStore";
import { isLoggedInRoleAdmin } from "@app/commonUtils/roleHelper";
import { isLoggedIn } from "../rootState";
import { PresentationSlideList } from "../presentationsList/presentationSlideList/index";
import { ProfileDescription } from "../profileDescription";
import { InvoiceList } from "../invoices";
import { ConsentList } from "../consent";
import { FortnoxSetup } from "../invoices/fortnox";
import { resetState } from "@app/reducers/actions";
import { ProfileAdaptabilityDescriptionList } from "../profileAdaptabilityDescriptions/list";

export const TemplateRoutes = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loggedInUserRole = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserRole,
  );

  const loading = useSelector((state: RootState) => state.mainReducer.spinner);

  const [isNotFound, setIsNotFound] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate(routePath.signIn);
      dispatch(resetState());
    }
  }, []);

  return (
    <div>
      {!isNotFound && <NavigationMenu />}
      <div className={clsx(!isNotFound && "container")}>
        <Routes>
          <Route
            path={routePath.addActivity}
            element={roleAccessControl(
              <AddProfileForm />,
              [Roles.Facilitator],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.employeeList}
            element={roleAccessControl(
              <EmployeeList />,
              [Roles.Facilitator],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.editEmployee}
            element={roleAccessControl(
              <EditEmployee />,
              [Roles.Facilitator],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.activities}
            element={roleAccessControl(
              <ActivityList />,
              [Roles.Facilitator, Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.profiles}
            element={roleAccessControl(
              <ProfileList />,
              [Roles.Admin, Roles.Facilitator],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.subscriptions}
            element={roleAccessControl(
              <PlanList />,
              [Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.coursesList}
            element={roleAccessControl(
              <CoursesList />,
              [Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.allaccounts}
            element={roleAccessControl(
              <ClientsList />,
              [Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.adminNewsList}
            element={roleAccessControl(
              <AdminNewsList />,
              [Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.adminDocumentList}
            element={roleAccessControl(
              <AdminDocumentList />,
              [Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />

          <Route
            path={routePath.adminProfileDescriptions}
            element={roleAccessControl(
              <ProfileDescription />,
              [Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.adminEmailTemplates}
            element={roleAccessControl(
              <EmailTemplateList />,
              [Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.adminLog}
            element={roleAccessControl(
              <LogEntryList />,
              [Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.adminAlerts}
            element={roleAccessControl(
              <AlertList />,
              [Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.latestDocument}
            element={roleAccessControl(
              <LatestDocuments />,
              [Roles.Facilitator, Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.information}
            element={roleAccessControl(
              <Resources />,
              [Roles.Facilitator, Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.latestNews}
            element={roleAccessControl(
              <LatestNews />,
              [Roles.Facilitator, Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.latestNewsPage}
            element={roleAccessControl(
              <LatestNewsPage />,
              [Roles.Facilitator, Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.latestBlogs}
            element={roleAccessControl(
              <LatestBlogs />,
              [Roles.Facilitator, Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.latestBlogPage}
            element={roleAccessControl(
              <LatestBlogPage />,
              [Roles.Facilitator, Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.languages}
            element={roleAccessControl(
              <LanguageList />,
              [Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.translations}
            element={roleAccessControl(
              <LanguageText />,
              [Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.placement}
            element={roleAccessControl(
              <PlacementList />,
              [Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.wordPairs}
            element={roleAccessControl(
              <WordsList />,
              [Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />

          <Route
            path={routePath.home}
            element={roleAccessControl(
              isLoggedInRoleAdmin(loggedInUserRole) ? (
                <AdminDashboard />
              ) : (
                <FacilitatorDashboard />
              ),
              [Roles.Admin, Roles.Facilitator],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.userProfile}
            element={roleAccessControl(
              <UserProfile />,
              [Roles.Admin, Roles.Facilitator],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.account}
            element={roleAccessControl(
              <Account />,
              [Roles.Admin, Roles.Facilitator],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.adminusers}
            element={roleAccessControl(
              <UsersList roleId={RoleEnum.Admin} />,
              [Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.addActivity}
            element={roleAccessControl(
              <EditActivity />,
              [Roles.Facilitator, Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.editActivity}
            element={roleAccessControl(
              <EditActivity />,
              [Roles.Facilitator, Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.allfacilitators}
            element={roleAccessControl(
              <UsersList roleId={RoleEnum.Facilitator} />,
              [Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.allparticipants}
            element={roleAccessControl(
              <UsersList roleId={RoleEnum.Participant} />,
              [Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.presentationTemplateList}
            element={roleAccessControl(
              <PresentationTemplateList />,
              [Roles.Admin, Roles.Facilitator],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.upcomingPresentations}
            element={roleAccessControl(
              <UpcomingPresentationList />,
              [Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.slidesList}
            element={roleAccessControl(
              <PresentationSlideList />,
              [Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.adminInvoices}
            element={roleAccessControl(
              <InvoiceList />,
              [Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.adminConsents}
            element={roleAccessControl(
              <ConsentList />,
              [Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.fortnoxSetup}
            element={roleAccessControl(
              <FortnoxSetup />,
              [Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path={routePath.profileAdaptabilityDescriptions}
            element={roleAccessControl(
              <ProfileAdaptabilityDescriptionList />,
              [Roles.Admin],
              loggedInUserRole,
              loading,
            )}
          />
          <Route
            path="*"
            element={<NotFoundPage setIsNotFound={setIsNotFound} />}
          />
        </Routes>

        <TermsFooter footerContainerClassName="px-0" />
      </div>
    </div>
  );
};
