import { Dispatch } from "redux";

import { rawAxiosApi, apiEndPoints } from "../../../api";
import { setNotification } from "../../actions";
import { ICreateUpdateClient, ICreateUpdateSubscriptionBody } from "./hooks";
import { IDisconnectUserFromClientBody } from "./utils/clientUsers/hooks";
import { ICountry, ITableCommonParams } from "@app/containers/commonInterfaces";
import { UserId } from "@app/containers/reducer";
import { ApiResponse, ApiResult } from "@app/types";
import { RoleEnum } from "@app/containers/commonEnums";

export interface IUsersForClient {
  id: UserId;
  clientId: number;
  name: string;
  email: string;
  isDefaultClient: boolean;
  roleId: RoleEnum;
  roleName: string;
}

export const getUsersByClientId = async (
  clientId: number,
  params: ITableCommonParams,
  dispatch: Dispatch,
): Promise<ApiResult<IUsersForClient>> => {
  try {
    const response = await rawAxiosApi.get<
      ApiResponse<ApiResult<IUsersForClient>>
    >(apiEndPoints.getUsersByClientId(clientId), {
      params: params,
    });
    if (!response.data.success) {
      return Promise.reject(response.data);
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getAllCountries = async (
  dispatch: Dispatch,
): Promise<Array<ICountry>> => {
  const body = {
    maxResultCount: 100,
    skipCount: 0,
  };

  try {
    const response = await rawAxiosApi.get<ApiResponse<ApiResult<ICountry>>>(
      apiEndPoints.getCountries,
      {
        params: body,
      },
    );

    if (!response.data.success) {
      return Promise.reject(response.data);
    }

    const countries: ICountry[] = response.data.result.items.slice();
    countries.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    );

    return countries;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const createClient = async (
  body: ICreateUpdateClient,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.createClient,
      JSON.stringify(body),
      {},
    );
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const updateClient = async (
  body: ICreateUpdateClient,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.put(
      apiEndPoints.updateClient,
      JSON.stringify(body),
      {},
    );
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const createClientByFacilitator = async (
  body: ICreateUpdateClient,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.createClientByFacilitator,
      JSON.stringify(body),
    );
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getAllClientSubscriptions = async (
  filter: string,
  sorting: string,
  maxResultCount: number,
  skipCount: number,
  clientId: number,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.get(
      apiEndPoints.getAllClientSubscriptions,
      {
        params: { filter, sorting, maxResultCount, skipCount, clientId },
      },
    );
    return response.data.result.items;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getClientSubscriptionById = async (
  subscriptionId: number,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.get(
      apiEndPoints.getClientSubscriptionById,
      {
        params: { id: subscriptionId },
      },
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

// Create client billing
export const createClientBilling = async (
  body: ICreateUpdateSubscriptionBody,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.createClientBilling,
      JSON.stringify(body),
      {},
    );
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

// Update client billing
export const updateClientBilling = async (
  body: ICreateUpdateSubscriptionBody,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.put(
      apiEndPoints.updateClientBilling,
      JSON.stringify(body),
      {},
    );
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

type AccountConnectedUser = {
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
};

// Get Users To Connect Account
export const getUsersToConnectAccount = async (
  clientId: number,
  searchText: string,
  dispatch: Dispatch,
): Promise<Array<AccountConnectedUser>> => {
  try {
    const response = await rawAxiosApi.get<
      ApiResponse<Array<AccountConnectedUser>>
    >(apiEndPoints.getUsersToConnectAccount, {
      params: { clientId, searchText },
    });
    if (!response.data.success) {
      return Promise.reject(response.data);
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

// Connect User to Client
export const connectFacilitatorToAccount = async (
  userId: UserId,
  clientId: number,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.connectFacilitatorToAccount,
      {},
      {
        params: { userId, clientId, isDefaultClient: false },
      },
    );
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

// Disconnect User from Client
export const disconnectUserFromClient = async (
  body: IDisconnectUserFromClientBody,
  dispatch: Dispatch,
): Promise<boolean> => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.disconnectUserFromClient,
      JSON.stringify(body),
      {},
    );
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
