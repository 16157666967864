import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import {
  getLanguageValue,
  ITranslationObject,
} from "../../../../commonUtils/languageFunctionsHelper";
import {
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import {
  previewParticipantInvitation,
  sendParticipantsInvitation,
} from "../actions";
import {
  ISelectedProfilesList,
  ISendParticipantsInvitationBody,
} from "../activityInviteParticipantsStep";
import { EmailSMSInvitation } from "./emailSMSInvitation";
import { ISavedMessage } from "./invitationMessageListModal";
import {
  TabNavItem,
  ITabNavItem,
} from "../../../../components/multiTabComponent/tabNavItem";
import { TabContent } from "../../../../components/multiTabComponent/tabContent";
import {
  getInvitationNavTabs,
  InvitationNavTabs,
} from "../../../../commonUtils/invitationFunctionsHelper";
import { InvitationType } from "../../activityEnums";
import { CustomModal as SMSWarningModal } from "../../../../components/modals/customModal";
import { setSpinner } from "../../../actions";
import { ModalComponent } from "../../../../components/modals/modalComponent";
import { LanguageCode } from "@app/containers/commonEnums";
import { ILanguage } from "@app/containers/commonInterfaces";

interface IPersonalizedInvitationModalProps {
  languageText: ITranslationObject;
  messageTranslation: ITranslationObject;
  languages: ReadonlyArray<ILanguage>;
  cultureValue: string;
  profile: ISelectedProfilesList;
  phoneNumber?: string;
  latestEmailSentLog?: string;
  selectedInvitationTypeBtn?: InvitationType;
  handleCloseClick: () => void;
  handleMessageSentSuccess: (
    isEmail: boolean,
    profile: ISelectedProfilesList,
    emailOrSMSStatus: number,
  ) => void;
}

export const PersonalizedInvitationModal = (
  props: IPersonalizedInvitationModalProps,
) => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState<InvitationNavTabs>(
    InvitationNavTabs.email,
  );
  const [emailLanguage, setEmailLanguage] = useState<string>("");
  const [emailInvitationText, setEmailInvitationText] = useState<string>(
    props.profile.emailOwnMessage || "",
  );
  const [emailSelectedMessageId, setEmailSelectedMessageId] =
    useState<number>(0);
  const [smsLanguage, setSMSLanguage] = useState<string>("");
  const [smsInvitationText, setSMSInvitationText] = useState<string>(
    props.profile.smsOwnMessage || "",
  );
  const [smsSelectedMessageId, setSMSSelectedMessageId] = useState<number>(0);
  const [previewHTML, setPreviewHTML] = useState<string>("");
  const [isSending, setIsSending] = useState<boolean>(false);
  const [updatedTabs, setUpdatedTabs] = useState<
    ITabNavItem<InvitationNavTabs>[]
  >([]);
  const [smsWarningModal, setSmsWarningModal] = useState<boolean>(false);

  const invitationNavTabs = useMemo(
    () => getInvitationNavTabs(props.languageText),
    [],
  );

  useEffect(() => {
    const lang = props.languages.find((l) => l.name === props.cultureValue);

    // the culture isn't necessarily a valid profile language.
    setEmailLanguage(lang?.name || LanguageCode.English);
    setSMSLanguage(lang?.name || LanguageCode.English);
    if (props.selectedInvitationTypeBtn) {
      let updatedTabs: ITabNavItem<InvitationNavTabs>[] = [];
      if (props.selectedInvitationTypeBtn === InvitationType.email) {
        updatedTabs = invitationNavTabs.slice(0, 1);
      } else {
        updatedTabs = invitationNavTabs.slice(1);
        setActiveTab(InvitationNavTabs.sms);
      }
      setUpdatedTabs(updatedTabs);
    }
  }, []);

  const handleEmailLanguageSelect = (
    selectedItem: IDropdownSelectedItem,
  ): void => {
    setEmailLanguage(selectedItem.value);
  };

  const onEmailTextChange = (value: string): void => {
    setEmailInvitationText(value);
  };

  const handleSelectedEmail = (selectedMessage: ISavedMessage): void => {
    setEmailInvitationText(selectedMessage.message);
    setEmailSelectedMessageId(selectedMessage.id);
  };

  const handleSMSLanguageSelect = (
    selectedItem: IDropdownSelectedItem,
  ): void => {
    setSMSLanguage(selectedItem.value);
  };

  const onSMSTextChange = (value: string): void => {
    setSMSInvitationText(value);
  };

  const handleSelectedSMS = (selectedMessage: ISavedMessage): void => {
    setSMSInvitationText(selectedMessage.message);
    setSMSSelectedMessageId(selectedMessage.id);
  };

  const handleSendClick = (
    isEmail: boolean,
    participant: ISelectedProfilesList,
  ): void => {
    if (!isEmail && !props.phoneNumber) {
      setSmsWarningModal(true);
      return;
    }

    setIsSending(true);
    dispatch(setSpinner(true));
    const languageValue: string = isEmail ? emailLanguage : smsLanguage;
    const languageId: number =
      props.languages.find((language) => language.name === languageValue)?.id ??
      0;
    let isReminder = false;
    if (isEmail && participant.emailStatus && participant.emailStatus > 1)
      isReminder = true;
    else if (!isEmail && participant.smsStatus && participant.smsStatus > 1)
      isReminder = true;

    const body: ISendParticipantsInvitationBody = {
      id: participant.id,
      message: isEmail ? emailInvitationText : smsInvitationText,
      ownMessage: "",
      savedMessageId: isEmail ? emailSelectedMessageId : smsSelectedMessageId,
      isEmailOrSms: isEmail,
      languageId: languageId,
      isEmailOrSmsReminder: isReminder,
      phoneNumber: props.phoneNumber ?? "",
    };

    sendParticipantsInvitation([body], languageValue, dispatch)
      .then((response) => {
        if (response?.length > 0) {
          const emailOrSMSStatus = isEmail
            ? response[0].emailStatus
            : response[0].smsStatus;
          props.handleMessageSentSuccess(
            isEmail,
            participant,
            emailOrSMSStatus,
          );
        }
      })
      .finally(() => {
        setIsSending(false);
        dispatch(setSpinner(false));
      });
  };

  const handlePreviewClick = (participant: ISelectedProfilesList): void => {
    const languageId: number =
      props.languages.find((language) => language.name === emailLanguage)?.id ??
      0;
    let isReminder = false;
    if (participant.emailStatus && participant.emailStatus > 1)
      isReminder = true;

    const body: ISendParticipantsInvitationBody = {
      id: participant.id,
      message: emailInvitationText,
      ownMessage: "",
      savedMessageId: emailSelectedMessageId,
      isEmailOrSms: true,
      languageId: languageId,
      isEmailOrSmsReminder: isReminder,
    };

    previewParticipantInvitation(body, emailLanguage, dispatch).then(
      (response) => {
        if (response) {
          setPreviewHTML(response);
        }
      },
    );
  };

  const closePreviewModal = (): void => setPreviewHTML("");

  const closeWarningModal = (): void => setSmsWarningModal(false);

  return (
    <>
      <ModalComponent
        width="lg"
        headerText={getLanguageValue(
          props.languageText,
          (props.profile && (props.profile.emailStatus ?? 0) > 1) ||
            props.latestEmailSentLog
            ? "Reminder"
            : "Invitation",
        )}
        cancelButtonText={getLanguageValue(props.languageText, "Close")}
        handleCancelClick={props.handleCloseClick}
      >
        <TabNavItem
          navTabs={updatedTabs.length > 0 ? updatedTabs : invitationNavTabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />

        {/* Email Invitation */}
        <TabContent id={InvitationNavTabs.email} activeTab={activeTab}>
          <EmailSMSInvitation
            isEmail={true}
            languageText={props.languageText}
            messageTranslation={props.messageTranslation}
            languages={props.languages}
            languageValue={emailLanguage}
            invitationText={emailInvitationText}
            profile={props.profile}
            sameInvitationForAll={false}
            invitationSent={props.profile.emailSent}
            previewHTML={previewHTML}
            sendingInvite={isSending}
            handleLanguageSelect={handleEmailLanguageSelect}
            handleInvitationTextChange={onEmailTextChange}
            handleSelectedMessage={handleSelectedEmail}
            handleSendClick={handleSendClick}
            handlePreviewClick={handlePreviewClick}
            closePreviewModal={closePreviewModal}
          />
        </TabContent>

        {/* SMS Invitation */}
        <TabContent id={InvitationNavTabs.sms} activeTab={activeTab}>
          <EmailSMSInvitation
            isEmail={false}
            languageText={props.languageText}
            messageTranslation={props.messageTranslation}
            languages={props.languages}
            languageValue={smsLanguage}
            invitationText={smsInvitationText}
            profile={props.profile}
            sameInvitationForAll={false}
            invitationSent={props.profile.smsSent}
            sendingInvite={isSending}
            handleLanguageSelect={handleSMSLanguageSelect}
            handleInvitationTextChange={onSMSTextChange}
            handleSelectedMessage={handleSelectedSMS}
            handleSendClick={handleSendClick}
          />
        </TabContent>

        {smsWarningModal && (
          <SMSWarningModal
            headerText={getLanguageValue(props.languageText, "No Phone Number")}
            bodyText={getLanguageValue(
              props.languageText,
              "Phone number is required to send the sms",
            )}
            submitButtonText={getLanguageValue(props.languageText, "Ok")}
            handleCancelClick={closeWarningModal}
            handleSubmitClick={closeWarningModal}
          />
        )}
      </ModalComponent>
    </>
  );
};
