import { Dispatch } from "redux";

import { rawAxiosApi, apiEndPoints } from "../../../api";
import { setNotification } from "../../actions";
import {
  IGetAllClientsByUserBody,
  IUpdateFacilitatorDefaultClientBody,
} from "./hooks";
import { ApiResponse, ApiResult } from "@app/types";
import { IClient } from "@app/containers/commonInterfaces";
import { UserId } from "@app/containers/reducer";

export const getAllClientsByUser = async (
  body: IGetAllClientsByUserBody,
  dispatch: Dispatch,
): Promise<ApiResult<IClient>> => {
  try {
    const response = await rawAxiosApi.get<ApiResponse<ApiResult<IClient>>>(
      apiEndPoints.getAllClientsByUser,
      {
        params: body,
      },
    );
    if (!response.data.success) {
      return await Promise.reject(response.data);
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const updateFacilitatorDefaultClient = async (
  body: IUpdateFacilitatorDefaultClientBody,
  dispatch: Dispatch,
): Promise<boolean> => {
  try {
    const response = await rawAxiosApi.put<ApiResponse<boolean>>(
      apiEndPoints.updateFacilitatorDefaultClient,
      body,
      {},
    );
    if (!response.data.success) {
      throw new Error(response.data.error?.message || "Very bad error");
    }
    return true;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const deleteFacilitatorClient = async (
  clientId: number,
  dispatch: Dispatch,
): Promise<unknown> => {
  try {
    const response = await rawAxiosApi.delete(
      apiEndPoints.deleteFacilitatorClient,
      {
        params: { clientId },
      },
    );
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
