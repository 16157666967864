import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AnyAction } from "redux";

import { routePath } from "../../routePaths";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { IDropdownList } from "@app/components/formComponents/dropdownSelect";
import { RootState } from "@app/store/configureStore";
import {
  createActivityCulture,
  createProfileParticipants,
  getProfileEmployeesByClientId,
  updateActivityClient,
  updateActivityRespondents,
  updateActivityRole,
  updateActivityType,
} from "./actions";
import {
  AccountSelectBtns,
  IndividualGroupBtnsEnum,
  ParticipantChangeRole,
  ProfileRoleStatus,
  RoleBtnsEnum,
  TypeOfRole,
} from "../activityEnums";
import { IInviteRespondents } from "./activityRespondentsStep";
import { IRoleStepData } from "./activityRoleStep";
import {
  IFetchParticipantsParams,
  IParticipantData,
  IParticipantRoleData,
} from "./activityParticipantsStep";
import React from "react";
import { getAllClientsByUser } from "../../usersProfile/account/actions";
import { IGetAllClientsByUserBody } from "../../usersProfile/account/hooks";
import { setCurrentClient } from "../../auth/signUp/actions";
import { getTypeOfRoleButtonList } from "../../../commonUtils/roleSettingsFunctionsHelper";
import { addToast, setSpinner } from "../../actions";
import { getParticipantLanguages } from "@app/containers/languageList/languageTexts/actions";
import { IClient, ILanguage } from "@app/containers/commonInterfaces";
import { IActivityInfo } from "./activityDescriptionStep";
import { ActivityId, ProfileId, UserId } from "@app/containers/reducer";

export interface IUpdateActivityTypeBody {
  activityId: ActivityId;
  activityType: boolean;
}

export interface IUpdateActivityClientBody {
  activityId: ActivityId;
  clientId: number;
}

export interface IRoleStepBody extends IRoleStepData {
  id: number;
}

export interface IUpdateActivityRespondentsBody {
  id: ActivityId;
  participantInviteExternal: boolean;
  participantInviteColleagues: boolean;
  participantInviteOtherParticipant: boolean;
}

export interface ICreateProfileBody {
  activityId: ActivityId;
  roleId: number;
  roleText: string;
  noOfRespondents: number;
  userId: UserId;
}

export interface ICreateProfileResponse {
  id: ProfileId;
  name: string;
  email: string;
  userId: UserId;
  activityId: ActivityId;
  phoneNumber: string;
}

export const useAddProfileModal = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loggedInUserRole = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserRole,
  );
  const currentClient = useSelector(
    (state: RootState) => state.loginReducer.currentClient,
  );
  const userDetails = useSelector(
    (state: RootState) => state.loginReducer.userDetails,
  );
  const userId = useSelector((state: RootState) => state.loginReducer.userId);
  const isConsultant: boolean = userDetails.isConsultant;

  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const [stepsCompleted, setStepsCompleted] = useState<number[]>([0]);
  const [languages, setLanguages] = useState<ReadonlyArray<ILanguage>>([]); // Participant languages

  // Select clients states
  const [clientData, setClientData] = useState<ReadonlyArray<IClient>>([]);
  const [clientEditMode, setClientEditMode] = useState<boolean>(false);
  // For consultant
  const [selectedClientBtn, setSelectedClientBtn] = useState<string>("");
  const [showEditModeConsultantClient, setShowEditModeConsultantClient] =
    useState<boolean>(false);

  //  First Step (Individual / Group) state
  const [selectedIndividualGroupBtn, setSelectedIndividualGroupBtn] =
    useState<string>("");
  const [showEditModeGroupSelect, setShowEditModeGroupSelect] =
    useState<boolean>(true);

  //  Second Step (Description) states
  const [createdActivityId, setCreatedActivityId] = useState<ActivityId>(
    0 as ActivityId,
  );
  const [selectedCulture, setSelectedCulture] = useState<string>("");
  const [profileInfo, setProfileInfo] = useState<IActivityInfo | undefined>(
    undefined,
  );
  const [showDescriptionSaveBtn, setShowDescriptionSaveBtn] =
    useState<boolean>(true);

  //  Third Step (Roles) state
  const [selectedSetRoleBtn, setSelectedSetRoleBtn] = useState<string>(""); // SET ROLE STATE
  const [selectedSameRoleBtn, setSelectedSameRoleBtn] = useState<string>(""); // ROLE SETTINGS STATE

  // FIXME: what's the idea behind this bonus state? we already have a nice
  //   object called 'roleSettings'. this makes things almost impossible to reason
  //   about - we have to consider two places to know which state the view is in.
  //   - johan, 2024-11-21
  const [selectedTypeOfRoleBtn, setSelectedTypeOfRoleBtn] =
    useState<string>(""); // TYPE OF ROLE STATES BEGIN
  const [typeOfRoleInput, setTypeOfRoleInput] = useState<string>("");
  const [customTypeOfRole, setCustomTypeOfRole] = useState<string>("");
  const [roleInputSelected, setRoleInputSelected] = useState<boolean>(false);
  const [selectedNoOfRespondentsBtn, setSelectedNoOfRespondentsBtn] =
    useState<string>(""); // NUMBER OF RESPONDENTS STATES BEGIN
  const [noOfRespondentsInput, setNoOfRespondentsInput] = useState<string>("");
  const [customNoOfRespondents, setCustomNoOfRespondents] =
    useState<string>("");
  const [respondentsInputSelected, setRespondentsInputSelected] =
    useState<boolean>(false);
  const [respondentsConfirmModal, setRespondentsConfirmModal] =
    useState<boolean>(false);
  const [selectedParticipantChangeBtn, setSelectedParticipantChangeBtn] =
    useState<string>(""); // PARTICIPANT CHANGE ROLE STATE
  const [showRoleSaveBtn, setShowRoleSaveBtn] = useState<boolean>(true);
  const [roleStepError, setRoleStepError] = useState<string>("");
  const [showEditModeNoOfRespondent, setShowEditModeNoOfRespondent] =
    useState<boolean>(true);
  const [showEditModeRoleType, setShowEditModeRoleType] =
    useState<boolean>(true);
  const [showEditModeRoleButtonList, setShowEditModeRoleButtonList] =
    useState<boolean>(true);
  const [showEditModeSelectedSameRoleBtn, setShowEditModeSelectedSameRoleBtn] =
    useState<boolean>(true);
  const [
    showEditModeSelectedParticipantBtnClick,
    setshowEditModeSelectedParticipantBtnClick,
  ] = useState<boolean>(true);

  const [roleSettings, setRoleSettings] = useState<IRoleStepData>({
    roleSetBy: 0,
    roleSameForAll: false,
    roleId: TypeOfRole.None,
    roleText: "",
    noOfRespondents: 0,
    roleCanBeChangedByParticipant: false,
  });

  const typeOfRoleButtonList = useMemo(
    () => getTypeOfRoleButtonList(languageText),
    [languageText],
  );

  // Fourth Step (Respondents) state
  const initialRespondentState: IInviteRespondents = {
    isInviteExternal: true,
    isInviteColleagues: false,
    isInviteOtherParticipant: false,
  };
  const [inviteRespondents, setInviteRespondents] =
    useState<IInviteRespondents>(initialRespondentState);
  const [showRespondentsSaveBtn, setShowRespondentsSaveBtn] =
    useState<boolean>(true);

  // Fifth Step (Participants) state
  const [filter, setFilter] = useState<string>("");
  const [participantsList, setParticipantsList] = useState<IParticipantData[]>(
    [],
  );
  const [selectedParticipants, setSelectedParticipants] = useState<
    IParticipantRoleData[]
  >([]);
  const [participantError, setParticipantError] = useState<string>("");
  const [showParticipantSaveBtn, setShowParticipantSaveBtn] =
    useState<boolean>(true);
  const [selectedParticipantsResponse, setSelectedParticipantsResponse] =
    useState<ICreateProfileResponse[]>([]);
  const [respondentErrorMessage, setRespondentErrorMessage] = useState("");

  useEffect(() => {
    setSelectedIndividualGroupBtn(IndividualGroupBtnsEnum.group);
    if (isConsultant) {
      setSelectedClientBtn(AccountSelectBtns.Select);
      setStepsCompleted([]);
    }
  }, []);

  // Scroll to particular step
  const scrollToParticularStep = (classname: string) => {
    if (scrollRef.current) {
      const element = scrollRef.current.querySelector(classname);

      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    const nextStep = stepsCompleted[stepsCompleted.length - 1];

    switch (nextStep) {
      case 2:
        scrollToParticularStep("#activityRoleStep");
        break;
      case 3:
        scrollToParticularStep("#activityRespondentStep");
        break;
      case 4:
        scrollToParticularStep("#activityParticipantStep");
        break;
      case 5:
        scrollToParticularStep("#activityInvitationStep");
        break;
      default:
        break;
    }
  }, [stepsCompleted]);

  // Select clients
  const fetchClientDetails = async () => {
    dispatch(setSpinner(true));
    const body: IGetAllClientsByUserBody = {
      userId: userId,
      roleName: loggedInUserRole ?? "",
      filter: "",
      sorting: "",
      maxResultCount: 1000,
      skipCount: 0,
    };
    getAllClientsByUser(body, dispatch)
      .then((res) => {
        setClientData(res.items);
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  useEffect(() => {
    if (!userId) {
      return;
    }
    fetchClientDetails();
  }, [userId]);

  const openSelectClientEditMode = (): void => {
    setClientEditMode(true);
  };

  const closeClientSelectModal = (): void => {
    setClientEditMode(false);
  };

  const handleClientSelect = (clientId: number) => {
    // FIXME: this works by coincidence. switching the current client requires
    //   a backend call to 'SwitchUserRoles' which we aren't doing here.
    //
    //   we switch the 'default' (current) client to avoid user confusion after
    //   the activity is created, because the activity list only shows activites
    //   for the current client.
    //
    //   -johan, 2024-09-04

    dispatch(
      setCurrentClient({
        currentClientId: clientId,
        currentClientName: String(
          clientData.find((c) => c.id === clientId)?.name ?? "",
        ),
      }),
    );

    if (clientEditMode && stepsCompleted.length > 1) {
      dispatch(setSpinner(true));
      const body: IUpdateActivityClientBody = {
        activityId: createdActivityId,
        clientId: clientId,
      };
      updateActivityClient(body, dispatch)
        .then((response) => {
          if (response) {
            closeClientSelectModal();
            fetchParticipants(createdActivityId, "", clientId);
            dispatch(addToast("Client updated successfully") as AnyAction);
          }
        })
        .finally(() => dispatch(setSpinner(false)));
    } else {
      closeClientSelectModal();
    }
  };

  const refreshClientList = (): void => {
    fetchClientDetails();
  };

  // Select clients for Consultant
  const handleConsultantClientBtn = (name: string): void => {
    setSelectedClientBtn(name);
  };

  const handleConsultantClientSelect = (clientId: number): void => {
    dispatch(
      setCurrentClient({
        currentClientId: clientId,
        currentClientName: String(
          clientData.find((c) => c.id === clientId)?.name ?? "",
        ),
      }),
    );
  };

  const handleSaveConsultantClient = (): void => {
    if (!stepsCompleted.includes(0) || !stepsCompleted.includes(1)) {
      setStepsCompleted([0]);
    }
  };

  //  First Step (Individual / Group)
  const clearRoleStep = (): void => {
    setSelectedSetRoleBtn("");
    setShowEditModeRoleButtonList(true);
    setSelectedSameRoleBtn("");
    setShowEditModeSelectedSameRoleBtn(true);
    setSelectedTypeOfRoleBtn("");
    setShowEditModeRoleType(true);
    setTypeOfRoleInput("");
    setCustomTypeOfRole("");
    setRoleInputSelected(false);
    setSelectedNoOfRespondentsBtn("");
    setShowEditModeNoOfRespondent(true);
    setNoOfRespondentsInput("");
    setCustomNoOfRespondents("");
    setRespondentsInputSelected(false);
    setSelectedParticipantChangeBtn("");
    setshowEditModeSelectedParticipantBtnClick(true);
    setShowRoleSaveBtn(true);
  };

  const clearRespondentsStep = (): void => {
    setInviteRespondents(initialRespondentState);
  };

  const clearParticipantsStep = (): void => {
    setFilter("");
    setSelectedParticipants([]);
    setSelectedParticipantsResponse([]);
    setParticipantError("");
  };

  const updateActivityFirstStep = (): void => {
    dispatch(setSpinner(true));
    const body: IUpdateActivityTypeBody = {
      activityId: createdActivityId,
      activityType:
        selectedIndividualGroupBtn === IndividualGroupBtnsEnum.group
          ? false
          : true,
    };
    updateActivityType(body, dispatch)
      .then((response) => {
        if (response) {
          const selectedBtn =
            selectedIndividualGroupBtn === IndividualGroupBtnsEnum.group
              ? IndividualGroupBtnsEnum.individual
              : IndividualGroupBtnsEnum.group;
          if (selectedBtn === IndividualGroupBtnsEnum.individual) {
            clearRespondentsStep();
            clearParticipantsStep();
          }
          clearRoleStep();
          setStepsCompleted([1, 2]);
          setSelectedIndividualGroupBtn(selectedBtn);
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  const handleIndividualGroupBtns = (name: string): void => {
    if (createdActivityId) {
      updateActivityFirstStep();
    } else setSelectedIndividualGroupBtn(name);
  };

  const handleFirstStepSave = (): void => {
    setShowEditModeGroupSelect(false);
    setStepsCompleted([1]);
  };

  //  Second Step (Description)
  const handleSecondStepSave = (
    createProfileInfo: IActivityInfo,
    selectedCulture: string,
  ): void => {
    if (!createdActivityId) {
      setProfileInfo(createProfileInfo);
    }
    setShowDescriptionSaveBtn(false);
    setSelectedCulture(selectedCulture);
    if (!stepsCompleted.includes(2)) {
      setStepsCompleted((prev) => [...prev, 2]);
    }
  };

  //  Third Step (Role)
  useEffect(() => {
    setNoOfRespondentsInput(customNoOfRespondents);
    if (customNoOfRespondents) {
      setRespondentsInputSelected(true);
    } else setRespondentsInputSelected(false);
  }, [customNoOfRespondents]);

  useEffect(() => {
    setTypeOfRoleInput(customTypeOfRole);
    if (customTypeOfRole) {
      setRoleInputSelected(true);
    } else setRoleInputSelected(false);
  }, [customTypeOfRole]);

  const showRoleSaveButton = (): void => {
    if (!showRoleSaveBtn) {
      setShowRoleSaveBtn(true);
      setStepsCompleted([1, 2]);
    }
  };

  const clearRoleStepError = (): void => {
    if (roleStepError) setRoleStepError("");
  };

  // Third Step (SET ROLE FUNCTION)
  const onSetRoleBtnClick = (name: string): void => {
    clearRoleStepError();
    showRoleSaveButton();
    setShowEditModeRoleButtonList(false);
    setSelectedSetRoleBtn(name);
    if (name !== ProfileRoleStatus.RoleSetByFacilitator) {
      if (selectedSameRoleBtn) setSelectedSameRoleBtn("");
      if (selectedTypeOfRoleBtn) setSelectedTypeOfRoleBtn("");
      if (customTypeOfRole) setCustomTypeOfRole("");
      if (selectedNoOfRespondentsBtn) setSelectedNoOfRespondentsBtn("");
      if (customNoOfRespondents) setCustomNoOfRespondents("");
      if (selectedParticipantChangeBtn) setSelectedParticipantChangeBtn("");
    }
  };

  // Third Step (ROLE SETTINGS FUNCTION)
  const onSameRoleBtnClick = (name: string): void => {
    clearRoleStepError();
    showRoleSaveButton();
    setShowEditModeSelectedSameRoleBtn(false);
    setSelectedSameRoleBtn(name);
    if (selectedTypeOfRoleBtn) setSelectedTypeOfRoleBtn("");
    if (customTypeOfRole) setCustomTypeOfRole("");
    if (selectedNoOfRespondentsBtn) setSelectedNoOfRespondentsBtn("");
    if (customNoOfRespondents) setCustomNoOfRespondents("");
    if (selectedParticipantChangeBtn) setSelectedParticipantChangeBtn("");
  };

  // Third Step (TYPE OF ROLE FUNCTIONS)
  const onTypeOfRoleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    showRoleSaveButton();
    setTypeOfRoleInput(e.target.value);
  };

  const onTypeOfRoleBtnClick = (name: string): void => {
    clearRoleStepError();
    showRoleSaveButton();
    setShowEditModeRoleType(false);
    if (roleInputSelected) {
      setShowEditModeRoleType(true);
    }
    setSelectedTypeOfRoleBtn(name);
    if (customTypeOfRole) setCustomTypeOfRole("");
  };

  const handleTypeOfRoleFocus = (
    e: React.FocusEvent<HTMLInputElement>,
  ): void => {
    const { value } = e.target;
    if (value) {
      clearRoleStepError();
      setRoleInputSelected(true);
      setShowEditModeRoleType(false);
    } else {
      if (roleInputSelected) setRoleInputSelected(false);
      setShowEditModeRoleType(true);
    }
    setCustomTypeOfRole(value);
    if (value && selectedTypeOfRoleBtn) setSelectedTypeOfRoleBtn("");
  };

  // Third Step (NUMBER OF RESPONDENTS FUNCTIONS)
  const onNoOfRespondentsChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    showRoleSaveButton();
    setNoOfRespondentsInput(e.target.value);
  };

  const onNoOfRespondentsClick = (name: string): void => {
    clearRoleStepError();
    showRoleSaveButton();
    setShowEditModeNoOfRespondent(false);
    if (respondentsInputSelected) setRespondentsInputSelected(false);
    setSelectedNoOfRespondentsBtn(name);
    if (customNoOfRespondents) setCustomNoOfRespondents("");
  };

  const handleCustomNoOfRespondents = (name: string): void => {
    setCustomNoOfRespondents(name);
    if (name && selectedNoOfRespondentsBtn) setSelectedNoOfRespondentsBtn("");
  };

  const handleNoOfRespondentsFocus = (
    e: React.FocusEvent<HTMLInputElement>,
  ): void => {
    const { value } = e.target;
    if (value) {
      clearRoleStepError();
      if (
        Number(value) > 8 &&
        Number(value) <= 20 &&
        value !== customNoOfRespondents
      ) {
        setRespondentsConfirmModal(true);
        setShowEditModeNoOfRespondent(false);
        return;
      } else if (
        value === "3" ||
        value === "4" ||
        value === "5" ||
        value === "6"
      ) {
        onNoOfRespondentsClick(value);
        setNoOfRespondentsInput("");
        setShowEditModeNoOfRespondent(false);
        return;
      } else if (value === "") {
        setShowEditModeNoOfRespondent(true);
      } else {
        setRespondentsInputSelected(true);
        setShowEditModeNoOfRespondent(false);
      }
    } else {
      if (respondentsInputSelected) setRespondentsInputSelected(false);
      setShowEditModeNoOfRespondent(true);
    }
    handleCustomNoOfRespondents(value);
  };

  const cancelRespondentsConfirmModal = (): void => {
    setRespondentsConfirmModal(false);
    setNoOfRespondentsInput("");
    setRespondentsInputSelected(false);
    setShowEditModeNoOfRespondent(true);
  };

  const SubmitRespondentsConfirmModal = (): void => {
    setRespondentsConfirmModal(false);
    setRespondentsInputSelected(true);
    handleCustomNoOfRespondents(noOfRespondentsInput);
  };

  // Third Step (PARTICIPANT CHANGE ROLE FUNCTIONS)
  const handleParticipantChangeRoleClick = (name: string): void => {
    clearRoleStepError();
    showRoleSaveButton();
    setSelectedParticipantChangeBtn(name);
    setshowEditModeSelectedParticipantBtnClick(false);
  };

  const handleThirdStepValidation = (): boolean => {
    let errorMsg: string = "";

    if (!selectedSetRoleBtn) {
      errorMsg = getLanguageValue(languageText, "Select role status");
    } else if (selectedSetRoleBtn === ProfileRoleStatus.RoleSetByFacilitator) {
      if (
        !selectedSameRoleBtn &&
        selectedIndividualGroupBtn === IndividualGroupBtnsEnum.group
      ) {
        errorMsg = getLanguageValue(languageText, "Select role setting");
      } else if (
        selectedSameRoleBtn === RoleBtnsEnum.sameRole ||
        selectedIndividualGroupBtn === IndividualGroupBtnsEnum.individual
      ) {
        if (!selectedTypeOfRoleBtn && !customTypeOfRole) {
          errorMsg = getLanguageValue(languageText, "Select role type");
        } else if (customNoOfRespondents && Number(customNoOfRespondents) < 3) {
          errorMsg = getLanguageValue(
            languageText,
            "Minimum 3 respondents should be selected",
          );
        } else if (
          customNoOfRespondents &&
          Number(customNoOfRespondents) > 20
        ) {
          errorMsg = getLanguageValue(
            languageText,
            "Maximum 20 respondents can be selected",
          );
        } else if (!selectedParticipantChangeBtn) {
          errorMsg = getLanguageValue(
            languageText,
            "Select participant role setting",
          );
        }
      } else if (!selectedParticipantChangeBtn) {
        errorMsg = getLanguageValue(
          languageText,
          "Select participant role setting",
        );
      }
    }
    if (
      selectedSetRoleBtn === ProfileRoleStatus.RoleSetByFacilitator &&
      (selectedSameRoleBtn === RoleBtnsEnum.sameRole ||
        selectedIndividualGroupBtn === IndividualGroupBtnsEnum.individual) &&
      selectedParticipantChangeBtn === ParticipantChangeRole.cannotChange &&
      !selectedNoOfRespondentsBtn &&
      !customNoOfRespondents
    ) {
      errorMsg = getLanguageValue(languageText, "Select no of respondents");
    }
    setRoleStepError(errorMsg);
    return errorMsg ? false : true;
  };

  const handleSaveRoleSettings = async (
    activityId: ActivityId,
  ): Promise<void> => {
    const body: IRoleStepBody = {
      ...roleSettings,
      id: activityId,
    };

    const response = await updateActivityRole(body, dispatch);
    if (response) {
      dispatch(addToast("Updated role settings") as AnyAction);
    }
  };

  const handleThirdStepSave = (): void => {
    if (!handleThirdStepValidation()) return;

    // i don't understand why we are modifying the state from the save button.
    // when we're ready to save the view should already be in an 'approved' state.
    //
    // here it looks like we're just ignoring anything we have in-memory and
    // deriving a new state altogether. how does that make sense?
    //   -johan, 2024-11-21
    const nextRoleSettings: IRoleStepData = {
      roleSetBy: Number(selectedSetRoleBtn),
      roleSameForAll:
        selectedSameRoleBtn === RoleBtnsEnum.sameRole ||
        selectedIndividualGroupBtn === IndividualGroupBtnsEnum.individual,
      roleId: Number(selectedTypeOfRoleBtn) || roleSettings.roleId,
      roleText: customTypeOfRole || roleSettings.roleText,
      noOfRespondents: customNoOfRespondents
        ? Number(customNoOfRespondents)
        : Number(selectedNoOfRespondentsBtn),
      roleCanBeChangedByParticipant:
        selectedParticipantChangeBtn === ParticipantChangeRole.canChange ||
        selectedSetRoleBtn === ProfileRoleStatus.RoleSetByParticipant,
    };
    setRoleSettings(nextRoleSettings);
    setShowRoleSaveBtn(false);
    setShowEditModeRoleButtonList(false);
    setShowEditModeRoleType(false);
    setShowEditModeSelectedSameRoleBtn(false);
    setshowEditModeSelectedParticipantBtnClick(false);
    if (!stepsCompleted.includes(3)) {
      setShowRespondentsSaveBtn(true);
      setStepsCompleted((prev) => [...prev, 3]);
    }
    if (createdActivityId) {
      handleSaveRoleSettings(createdActivityId);
    }
  };

  //  Fourth Step (Respondents)
  const showRespondentsSaveButton = (): void => {
    if (!showRespondentsSaveBtn) {
      setShowRespondentsSaveBtn(true);
      setStepsCompleted([1, 2, 3]);
    }
  };

  const handleRespondentsCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { name, checked } = e.target;

    setInviteRespondents((prev) => ({
      ...prev,
      [name]: checked,
    }));
    showRespondentsSaveButton();
  };

  const handleCreateActivity = async (): Promise<ActivityId> => {
    return await createActivityCulture(profileInfo!, dispatch).then(
      (response) => {
        setCreatedActivityId(response);
        dispatch(addToast("Activity created successfully") as AnyAction);
        return response;
      },
    );
  };

  const handleSaveRespondentSettings = async (
    activityId: ActivityId,
  ): Promise<void> => {
    setRespondentErrorMessage("");
    const body: IUpdateActivityRespondentsBody = {
      id: activityId,
      participantInviteExternal: inviteRespondents.isInviteExternal,
      participantInviteColleagues: inviteRespondents.isInviteColleagues,
      participantInviteOtherParticipant:
        inviteRespondents.isInviteOtherParticipant,
    };

    updateActivityRespondents(body, dispatch).then((response) => {
      if (response?.status === 200) {
        setShowRespondentsSaveBtn(false);
        setShowParticipantSaveBtn(true);
        setStepsCompleted((prev) => [...prev, 4]);
        dispatch(addToast("Updated respondents") as AnyAction);
        fetchParticipants(activityId);
      }
    });
  };

  const handleFourthStepSave = async (): Promise<void> => {
    const isValid = Object.values(inviteRespondents).some((value) => value);
    if (!isValid) {
      setRespondentErrorMessage(
        getLanguageValue(languageText, "Atleast 1 option should be selected"),
      );
      return;
    }
    dispatch(setSpinner(true));

    // If profile is already created, just update respondent settings
    if (createdActivityId) {
      handleSaveRespondentSettings(createdActivityId);
      return;
    }

    // Creating profile in the 4th step, then updating roles and respondents settings
    const activityId = await handleCreateActivity();

    if (activityId) {
      const saveProfileSettings = async () => {
        try {
          // Awaiting for 1 call to complete before starting the next call as it is updating same table
          // If called simultaneously, both api's are reading previous data from table and updating it
          await handleSaveRoleSettings(activityId);
          await handleSaveRespondentSettings(activityId);
        } finally {
          dispatch(setSpinner(false));
        }
      };

      saveProfileSettings();
    }
  };

  //  Fifth Step (Participants)
  const showParticipantsSaveButton = (): void => {
    if (!showParticipantSaveBtn) {
      setShowParticipantSaveBtn(true);
      setStepsCompleted([1, 2, 3, 4]);
    }
  };

  const fetchParticipants = (
    activityId: ActivityId,
    searchText = "",
    value = 0,
  ): void => {
    dispatch(setSpinner(true));

    const body: IFetchParticipantsParams = {
      activityId: activityId,
      clientId: value || currentClient.currentClientId,
      searchText: searchText,
    };

    getProfileEmployeesByClientId(body, dispatch)
      .then((response) => {
        let updatedParticipantList: IParticipantData[];
        if (response) {
          updatedParticipantList = response.items.map(
            (participant: IParticipantData) => ({
              id: participant.id,
              name: participant.name,
              surname: participant.surname,
            }),
          );
          // Removing already selected participants from list
          if (selectedParticipants) {
            updatedParticipantList = updatedParticipantList.filter(
              (participant) =>
                !selectedParticipants
                  .map((item) => item.id)
                  .includes(participant.id),
            );
          }
          setParticipantsList(updatedParticipantList);
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  const handleSearch = (value: string) => {
    setFilter(value);
    fetchParticipants(createdActivityId, value);
  };

  const clearParticipantError = (): void => {
    if (participantError) setParticipantError("");
  };

  const handleSelectParticipant = (participant: IParticipantData): void => {
    clearParticipantError();

    const roleUpdatedParticipant: IParticipantRoleData = {
      ...participant,
      roleId: roleSettings.roleId,
      roleText: roleSettings.roleText,
      noOfRespondents: roleSettings.noOfRespondents,
    };

    setSelectedParticipants((prev) => [...prev, roleUpdatedParticipant]);
    const updatedParticipants = participantsList.filter(
      (item) => item.id !== participant.id,
    );
    setParticipantsList(updatedParticipants);
    showParticipantsSaveButton();
  };

  const handleRemoveParticipant = (participant: IParticipantRoleData): void => {
    let updateSelectedParticipants: IParticipantRoleData[] = [];

    // Removing participant from selected participants
    updateSelectedParticipants = selectedParticipants.filter(
      (item) => item.id !== participant.id,
    );
    setSelectedParticipants(updateSelectedParticipants);

    // Adding back the removed participant to participant list
    let updateParticipants: IParticipantData[] = [];

    const isFiltered =
      participant.name.toLowerCase().includes(filter.toLowerCase()) ||
      participant.surname.toLowerCase().includes(filter.toLowerCase());
    if (isFiltered) {
      updateParticipants = [...participantsList];
      updateParticipants.push(participant); // Pushing the removed participant
      updateParticipants.sort((p1, p2) => p1.id - p2.id); // Arranging participant list based on id
      setParticipantsList(updateParticipants);
    }
    showParticipantsSaveButton();
    clearParticipantError();
  };

  const saveRoleSettings = (
    updatedParticipants: IParticipantRoleData[],
  ): void => {
    if (participantError) clearParticipantError();
    setSelectedParticipants(updatedParticipants);
    showParticipantsSaveButton();
    clearParticipantError();
  };

  const handleParticipantStepValidation = (): boolean => {
    let errorMsg: string = "";

    if (
      selectedIndividualGroupBtn === IndividualGroupBtnsEnum.group &&
      selectedParticipants.length > 0 &&
      roleSettings.roleSetBy ===
        Number(ProfileRoleStatus.RoleSetByFacilitator) &&
      !roleSettings.roleSameForAll &&
      !roleSettings.roleCanBeChangedByParticipant
    ) {
      for (let i = 0; i < selectedParticipants.length; i++) {
        const obj = selectedParticipants[i];
        if (!obj.noOfRespondents || (!obj.roleId && !obj.roleText)) {
          errorMsg = getLanguageValue(
            languageText,
            "Set role setting for all participants",
          );
          break;
        }
      }
    }

    setParticipantError(errorMsg);
    return errorMsg ? false : true;
  };

  const fetchParticipantLanguages = (): void => {
    getParticipantLanguages(dispatch).then((response) => {
      if (response && response.length > 0) {
        setLanguages(response);
      }
    });
  };

  const handleFifthStepSave = (): void => {
    if (!handleParticipantStepValidation()) return;

    const body: ICreateProfileBody[] = [];
    if (selectedParticipants.length > 0) {
      selectedParticipants.map((participant) => {
        body.push({
          activityId: createdActivityId,
          roleId: participant.roleId,
          roleText: participant.roleText,
          noOfRespondents: participant.noOfRespondents,
          userId: participant.id,
        });
      });
    }

    if (!selectedParticipants.length && !selectedParticipantsResponse.length) {
      navigate(
        routePath.editActivity.replace(":id", createdActivityId.toString()),
      );
    } else {
      dispatch(setSpinner(true));
      createProfileParticipants(body, createdActivityId, dispatch)
        .then((response) => {
          if (response?.success) {
            if (!selectedParticipants.length) {
              navigate(
                routePath.editActivity.replace(
                  ":id",
                  createdActivityId.toString(),
                ),
              );
            } else {
              setShowParticipantSaveBtn(false);
              setSelectedParticipantsResponse(response.result);
              setStepsCompleted((prev) => [...prev, 5]);
              dispatch(addToast("Updated participants") as AnyAction);
              if (languages.length === 0) fetchParticipantLanguages();
            }
          }
        })
        .finally(() => dispatch(setSpinner(false)));
    }
  };

  // Sixth Step (Invite Participants)
  const handleSixthStepSave = (): void => {
    navigate(
      routePath.editActivity.replace(":id", createdActivityId.toString()),
    );
  };

  return {
    scrollRef,
    languageText,
    isConsultant,
    selectedClientBtn,
    showEditModeConsultantClient,
    selectedIndividualGroupBtn,
    stepsCompleted,
    languages,
    createdActivityId,
    selectedCulture,
    showDescriptionSaveBtn,
    selectedSetRoleBtn,
    selectedSameRoleBtn,
    selectedTypeOfRoleBtn,
    typeOfRoleInput,
    customTypeOfRole,
    roleInputSelected,
    selectedNoOfRespondentsBtn,
    noOfRespondentsInput,
    customNoOfRespondents,
    respondentsInputSelected,
    respondentsConfirmModal,
    selectedParticipantChangeBtn,
    roleStepError,
    showRoleSaveBtn,
    roleSettings,
    inviteRespondents,
    showRespondentsSaveBtn,
    typeOfRoleButtonList,
    filter,
    participantsList,
    selectedParticipants,
    participantError,
    showParticipantSaveBtn,
    selectedParticipantsResponse,
    clientData,
    clientId: currentClient.currentClientId,
    clientEditMode,
    respondentErrorMessage,
    showEditModeNoOfRespondent,
    showEditModeRoleType,
    showEditModeRoleButtonList,
    showEditModeSelectedSameRoleBtn,
    showEditModeGroupSelect,
    showEditModeSelectedParticipantBtnClick,
    refreshClientList,
    handleConsultantClientBtn,
    handleConsultantClientSelect,
    handleSaveConsultantClient,
    handleIndividualGroupBtns,
    handleFirstStepSave,
    openSelectClientEditMode,
    handleClientSelect,
    setShowDescriptionSaveBtn,
    handleSecondStepSave,
    onSetRoleBtnClick,
    closeClientSelectModal,
    onSameRoleBtnClick,
    onTypeOfRoleChange,
    onTypeOfRoleBtnClick,
    handleTypeOfRoleFocus,
    onNoOfRespondentsChange,
    onNoOfRespondentsClick,
    handleNoOfRespondentsFocus,
    cancelRespondentsConfirmModal,
    SubmitRespondentsConfirmModal,
    handleParticipantChangeRoleClick,
    handleThirdStepSave,
    handleRespondentsCheckboxChange,
    handleFourthStepSave,
    handleSearch,
    handleSelectParticipant,
    handleRemoveParticipant,
    saveRoleSettings,
    handleFifthStepSave,
    handleSixthStepSave,
    setShowEditModeConsultantClient,
    setShowEditModeNoOfRespondent,
    setShowEditModeRoleType,
    setShowEditModeRoleButtonList,
    setShowEditModeSelectedSameRoleBtn,
    setShowEditModeGroupSelect,
    setshowEditModeSelectedParticipantBtnClick,
  };
};
