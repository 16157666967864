import React from "react";
import clsx from "clsx";

import { AddEditUserClientModal } from "./addEditUserClientModal";
import { ReactDatePicker } from "../../../components/datePicker/ReactDatePicker";
import { DropdownSelect } from "@app/components/formComponents/dropdownSelect";
import { Input } from "../../../components/formComponents/input";
import { ToggleCheckbox } from "@app/components/formComponents/toggleCheckbox";
import { ValidationBox } from "../../../components/newPasswordInputBox/validationBox";
import { PhoneNumberInput } from "../../employeeList/addEmployeeModal/phoneNumberInput";
import {
  IAddEditUserModalProps,
  useAddEditUserModal,
  UserInputs,
  isDefaultClientForEditMode,
} from "./hooks";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { E164Number } from "libphonenumber-js/types";
import { getRoleName, RoleEnum, Roles } from "../../commonEnums";
import {
  CustomModal as ActivationEmailModal,
  CustomModal,
} from "../../../components/modals/customModal";
import { ModalComponent } from "../../../components/modals/modalComponent";
import { useSelector } from "react-redux";
import { RootState } from "@app/store/configureStore";
import { isLoggedInRoleAdmin } from "@app/commonUtils/roleHelper";
import { hasOwnProperty } from "@app/containers/utils";

export const AddEditUserModal = (
  props: IAddEditUserModalProps,
): JSX.Element => {
  const {
    isParticipant,
    isValidText,
    isValidEmail,
    isEmailCheckBtnDisabled,
    userInfo,
    focusInput,
    clients,
    facilitatorLanguages,
    participantLanguages,
    isParticipantLanguage,
    isCommonLanguage,
    isSubmitting,
    activationEmailModal,
    phoneNumberError,
    editMode,
    showClientRoleModal,
    editClientId,
    isEmailVerified,
    verifyEmail,
    closeAlertModal,
    validatePhonenumber,
    onActivationEmailModalCancel,
    onActivationEmailModalClose,
    onActivationEmailModalSubmit,
    onSubmitClick,
    handleDateSelect,
    handleCheckboxChange,
    handleDropdownSelect,
    handleAddClientClick,
    handleEditClient,
    handleDeleteClient,
    closeUserClientModal,
    handleEditUserRoles,
    handleBlurEvent,
    handleInputChange,
    handlePhoneInputChange,
    closeModal,
  } = useAddEditUserModal(props);

  const roleName = props.clientIdFromAccount
    ? getRoleName(RoleEnum.Facilitator)
    : getRoleName(props.roleId);

  const loggedInUserRole = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserRole,
  );
  const rolesByClientId: { [clientId: number]: Array<RoleEnum> } = {};

  for (const role of userInfo.roles) {
    // a user might belong to several roles on a single client.
    if (!rolesByClientId[role.clientId]) {
      rolesByClientId[role.clientId] = [];
    }
    rolesByClientId[role.clientId].push(role.roleId);
  }

  // these two look extremely similar but there is a slight but important difference:
  // - to become a participant client you must have the 'Participant' role.
  // - to become a non-participant client you must have any other role than 'Participant',
  //   but you may also have that role. note that this is _not_ strictly the inverse of
  //   a participant client.
  //
  const nonParticipantClients = clients.filter(
    (c) =>
      hasOwnProperty(rolesByClientId, c.id) &&
      rolesByClientId[c.id].some((r) => r !== RoleEnum.Participant),
  );
  const participantClients = clients.filter((c) => {
    return (
      hasOwnProperty(rolesByClientId, c.id) &&
      rolesByClientId[c.id].includes(RoleEnum.Participant)
    );
  });

  return (
    <>
      <ModalComponent
        width="lg"
        headerText={getLanguageValue(
          props.languageText,
          !props.editUserId ? `Add ${roleName}` : `Edit ${roleName}`,
        )}
        cancelButtonText={getLanguageValue(props.languageText, "Cancel")}
        submitButtonText={getLanguageValue(props.languageText, "Submit")}
        handleCancelClick={closeModal}
        handleSubmitClick={onSubmitClick}
      >
        <div className="position-relative">
          {props.editUserId !== 0 && (
            <div className="position-absolute end-0">
              {isEmailVerified && (
                <span className="badge text-bg-success">
                  {getLanguageValue(props.languageText, "Verified")}
                </span>
              )}
              {!isEmailVerified && (
                <span className="badge text-bg-light">
                  {getLanguageValue(props.languageText, "Unverified")}
                </span>
              )}
            </div>
          )}
          <Input
            name={UserInputs.emailAddress}
            label={getLanguageValue(props.languageText, "Email")}
            placeholder={getLanguageValue(props.languageText, "Email")}
            value={userInfo.emailAddress}
            errorMessage={focusInput.emailAddress.errorMessage}
            handleBlurEvent={handleBlurEvent}
            handleInputChange={handleInputChange}
            required
          />
        </div>

        {!isValidEmail && (
          <button
            className="btn btn-success btn-sm"
            onClick={(e) => verifyEmail(e)}
            disabled={isEmailCheckBtnDisabled}
          >
            {getLanguageValue(props.languageText, "Check Availability")}
          </button>
        )}

        {isValidEmail && (
          <>
            <Input
              name={UserInputs.name}
              label={getLanguageValue(props.languageText, "Name")}
              placeholder={getLanguageValue(props.languageText, "Name")}
              value={userInfo.name}
              errorMessage={focusInput.name.errorMessage}
              handleBlurEvent={handleBlurEvent}
              handleInputChange={handleInputChange}
              required
            />
            <Input
              name={UserInputs.surname}
              label={getLanguageValue(props.languageText, "Surname")}
              placeholder={getLanguageValue(props.languageText, "Surname")}
              value={userInfo.surname}
              errorMessage={focusInput.surname.errorMessage}
              handleBlurEvent={handleBlurEvent}
              handleInputChange={handleInputChange}
              required
            />

            <PhoneNumberInput
              languageText={props.languageText}
              label={getLanguageValue(props.languageText, "Phonenumber")}
              value={(userInfo.phoneNumber as E164Number) || ""}
              onChange={(value) => handlePhoneInputChange(value)}
              onBlur={(e) => validatePhonenumber(e.target.value as E164Number)}
              errorFieldClassName="mt-2"
              phoneNumberError={phoneNumberError}
            />
            <Input
              name={UserInputs.password}
              label={getLanguageValue(props.languageText, "Password")}
              placeholder={getLanguageValue(props.languageText, "Password")}
              value={userInfo.password}
              errorMessage={focusInput.password.errorMessage}
              handleBlurEvent={handleBlurEvent}
              handleInputChange={handleInputChange}
              required={props.editUserId ? false : true}
              type="new-password"
            />
            <ValidationBox
              value={userInfo.password}
              languageText={props.languageText}
            />
            <Input
              name={UserInputs.confirmPassword}
              label={getLanguageValue(props.languageText, "Confirm Password")}
              placeholder={getLanguageValue(
                props.languageText,
                "Confirm Password",
              )}
              value={userInfo.confirmPassword}
              errorMessage={focusInput.confirmPassword.errorMessage}
              handleBlurEvent={handleBlurEvent}
              handleInputChange={handleInputChange}
              required={props.editUserId ? false : true}
              type="new-password"
            />

            <div className="d-flex gap-5 flex-wrap mb-2">
              <ReactDatePicker
                className="col-md-4 col-5"
                label={getLanguageValue(props.languageText, "Active from")}
                placeholder={getLanguageValue(
                  props.languageText,
                  "Select Start Date",
                )}
                name={UserInputs.startDate}
                date={userInfo.startDate}
                focusInput={focusInput.startDate}
                handleDateSelect={handleDateSelect}
                required
              />
            </div>

            {/* ----------- Non Participant Clients ----------- */}
            {(props.roleId !== RoleEnum.Admin ||
              (props.clientIdFromAccount &&
                nonParticipantClients.length > 0)) && (
              <div className="mt-4">
                <div className="d-flex justify-content-between align-items-center mb-7">
                  <h5 className="fw-bold">
                    {getLanguageValue(props.languageText, "Accounts")}
                  </h5>
                  {!props.clientIdFromAccount && (
                    <button
                      type="button"
                      className="btn btn-sm btn-success"
                      onClick={() => handleAddClientClick("non_participant")}
                    >
                      <i className="bi bi-plus-lg" />
                      {getLanguageValue(props.languageText, "Add Accounts")}
                    </button>
                  )}
                </div>

                {nonParticipantClients.map((item, index) => {
                  const isDefaultClient = isDefaultClientForEditMode(
                    item.id,
                    userInfo.roles,
                    "non_participant",
                  );

                  return (
                    <div key={index} className="my-2">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center gap-3">
                          <div className="py-1">{item.name}</div>
                          <i
                            className={clsx(
                              "bi bi-check-circle-fill",
                              isDefaultClient
                                ? "text-success"
                                : "text-secondary",
                            )}
                          />
                        </div>
                        <div className="d-flex gap-3">
                          <div
                            className={clsx(
                              "btn btn-light w-20px btn-sm d-flex justify-content-center align-items-center",
                              props.clientIdFromAccount && "disabled",
                            )}
                            onClick={() => {
                              handleEditClient(item.id, "non_participant");
                            }}
                          >
                            <i className="bi bi-pencil-fill"></i>
                          </div>
                          <div
                            className={clsx(
                              "btn btn-light-danger w-20px btn-sm d-flex justify-content-center align-items-center",
                              ((!isParticipant && isDefaultClient) ||
                                (isParticipant &&
                                  nonParticipantClients.length > 1 &&
                                  isDefaultClient)) &&
                                "disabled",
                            )}
                            onClick={() =>
                              handleDeleteClient(item.id, "non_participant")
                            }
                          >
                            <i className="bi bi-trash3 pe-0"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {!isParticipant &&
                  focusInput.nonParticipantClients.errorMessage && (
                    <div className="invalid-feedback d-inline-block">
                      {focusInput.nonParticipantClients.errorMessage}
                    </div>
                  )}
              </div>
            )}
            {((isCommonLanguage && !props.clientIdFromAccount) ||
              (props.clientIdFromAccount &&
                nonParticipantClients.length > 0)) && (
              <DropdownSelect
                name={UserInputs.languageId}
                label={getLanguageValue(props.languageText, "Language")}
                defaultLabel={getLanguageValue(
                  props.languageText,
                  "Select Language",
                )}
                list={facilitatorLanguages}
                value={userInfo.languageId}
                focusInput={focusInput.languageId}
                handleDropdownSelect={handleDropdownSelect}
                required
              />
            )}

            {/* ----------- Participant Accounts ----------- */}
            {(props.roleId !== RoleEnum.Admin ||
              (props.clientIdFromAccount && participantClients.length > 0)) && (
              <div className="mt-4 mb-2">
                <div className="d-flex justify-content-between align-items-center mb-7">
                  <h5 className="fw-bold">
                    {getLanguageValue(
                      props.languageText,
                      "Participant accounts",
                    )}
                  </h5>
                  {!props.clientIdFromAccount && (
                    <button
                      type="button"
                      className="btn btn-sm btn-success"
                      onClick={() => handleAddClientClick("participant")}
                    >
                      <i className="bi bi-plus-lg" />
                      {getLanguageValue(
                        props.languageText,
                        "Add participant account",
                      )}
                    </button>
                  )}
                </div>

                {participantClients.map((item, index) => {
                  const isDefaultClient = isDefaultClientForEditMode(
                    item.id,
                    userInfo.roles,
                    "participant",
                  );

                  return (
                    <div key={index} className="my-2">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center gap-3">
                          <div className="py-1">{item.name}</div>
                          <i
                            className={clsx(
                              "bi bi-check-circle-fill",
                              isDefaultClient
                                ? "text-success"
                                : "text-secondary",
                            )}
                          />
                        </div>
                        <div className="d-flex gap-3">
                          <div
                            className={clsx(
                              "btn btn-light w-20px btn-sm d-flex justify-content-center align-items-center",
                              props.clientIdFromAccount && "disabled",
                            )}
                            onClick={() => {
                              handleEditClient(item.id, "participant");
                            }}
                          >
                            <i className="bi bi-pencil-fill"></i>
                          </div>
                          <div
                            className={clsx(
                              "btn btn-light-danger w-20px btn-sm d-flex justify-content-center align-items-center",
                              ((isParticipant && isDefaultClient) ||
                                (!isParticipant &&
                                  participantClients.length > 1 &&
                                  isDefaultClient)) &&
                                "disabled",
                            )}
                            onClick={() =>
                              handleDeleteClient(item.id, "participant")
                            }
                          >
                            <i className="bi bi-trash3 pe-0"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {isParticipant &&
                  focusInput.participantClients.errorMessage && (
                    <div className="invalid-feedback d-inline-block">
                      {focusInput.participantClients.errorMessage}
                    </div>
                  )}
              </div>
            )}
            {((isParticipantLanguage && !props.clientIdFromAccount) ||
              (props.clientIdFromAccount && participantClients.length > 0)) && (
              <DropdownSelect
                name={UserInputs.participantLanguageId}
                label={getLanguageValue(
                  props.languageText,
                  "Participant Language",
                )}
                defaultLabel={getLanguageValue(
                  props.languageText,
                  "Select Language",
                )}
                list={participantLanguages}
                value={userInfo.participantLanguageId}
                focusInput={focusInput.participantLanguageId}
                handleDropdownSelect={handleDropdownSelect}
                required
              />
            )}

            <ToggleCheckbox
              label={getLanguageValue(props.languageText, "Is Active")}
              name={UserInputs.isActive}
              value={userInfo.isActive}
              handleCheckboxChange={handleCheckboxChange}
            />

            <ToggleCheckbox
              label={getLanguageValue(
                props.languageText,
                "Change Password On Next Login",
              )}
              name={UserInputs.shouldChangePasswordOnNextLogin}
              value={userInfo.shouldChangePasswordOnNextLogin}
              handleCheckboxChange={handleCheckboxChange}
            />

            <ToggleCheckbox
              label={getLanguageValue(
                props.languageText,
                "Is Two-Factor Enabled",
              )}
              name={UserInputs.isTwoFactorEnabled}
              value={userInfo.isTwoFactorEnabled}
              handleCheckboxChange={handleCheckboxChange}
            />

            <ToggleCheckbox
              label={getLanguageValue(props.languageText, "Enable Lockout")}
              name={UserInputs.isLockoutEnabled}
              value={userInfo.isLockoutEnabled}
              handleCheckboxChange={handleCheckboxChange}
            />
            {props.roleId === RoleEnum.Facilitator && (
              <ToggleCheckbox
                label={getLanguageValue(props.languageText, "Is Consultant")}
                name={UserInputs.isConsultant}
                value={userInfo.isConsultant}
                handleCheckboxChange={handleCheckboxChange}
              />
            )}
            {isLoggedInRoleAdmin(loggedInUserRole) && (
              <React.Fragment>
                <Input
                  name={UserInputs.sourceType}
                  label={getLanguageValue(props.languageText, "Source type")}
                  placeholder={getLanguageValue(
                    props.languageText,
                    "Source type",
                  )}
                  value={userInfo.sourceType}
                  handleInputChange={handleInputChange}
                />

                <Input
                  name={UserInputs.sourceAddress}
                  label={getLanguageValue(props.languageText, "Source address")}
                  placeholder={getLanguageValue(
                    props.languageText,
                    "Source address",
                  )}
                  value={userInfo.sourceAddress}
                  handleInputChange={handleInputChange}
                />

                <ToggleCheckbox
                  label={getLanguageValue(props.languageText, "Is manager")}
                  name={UserInputs.isManager}
                  value={userInfo.isManager}
                  handleCheckboxChange={handleCheckboxChange}
                />
              </React.Fragment>
            )}
          </>
        )}

        {/* Adding or editing Client */}
        {showClientRoleModal && editMode && (
          <AddEditUserClientModal
            languageText={props.languageText}
            clients={clients}
            initialClientId={editClientId}
            assignedRoles={userInfo.roles.slice()}
            editMode={editMode}
            closeUserClientModal={closeUserClientModal}
            handleEditUserRoles={handleEditUserRoles}
          />
        )}

        {activationEmailModal && (
          <>
            <ActivationEmailModal
              headerText={getLanguageValue(
                props.languageText,
                "Activation Email",
              )}
              bodyText={`${getLanguageValue(
                props.languageText,
                `Do you want to send activation email for`,
              )} "${userInfo.emailAddress}"`}
              cancelButtonText={getLanguageValue(props.languageText, "No")}
              submitButtonText={getLanguageValue(props.languageText, "Yes")}
              cancelButtonDisabled={isSubmitting}
              submitButtonDisabled={isSubmitting}
              handleSubmitClick={onActivationEmailModalSubmit}
              handleCancelClick={onActivationEmailModalCancel}
              handleCloseModal={onActivationEmailModalClose}
            />
          </>
        )}

        {isValidText !== "" && (
          <CustomModal
            headerText={getLanguageValue(props.languageText, "Alert")}
            bodyText={isValidText}
            cancelButtonText={getLanguageValue(props.languageText, "Ok")}
            handleCancelClick={closeAlertModal}
            handleSubmitClick={closeAlertModal}
          />
        )}
      </ModalComponent>
    </>
  );
};
