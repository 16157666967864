import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../../commonUtils/languageFunctionsHelper";
import { IUpdateActivityRespondentsBody } from "../../../addActivity/hooks";
import { updateActivityRespondents } from "../../../addActivity/actions";
import {
  ActivityRespondentsStep,
  IInviteRespondents,
} from "../../../addActivity/activityRespondentsStep";
import { addToast, setSpinner } from "../../../../actions";
import { ModalComponent } from "@app/components/modals/modalComponent";
import { AnyAction } from "redux";
import { ActivityId } from "@app/containers/reducer";

interface IEditProfileRespondentModalProps {
  id: ActivityId;
  languageText: ITranslationObject;
  isGroup: boolean;
  participantInviteExternal: boolean;
  participantInviteColleagues: boolean;
  participantInviteOtherParticipant: boolean;
  closeModal: () => void;
  handleSaveClick: (inviteRespondents: IInviteRespondents) => void;
}

export const EditProfileRespondentModal: React.FC<
  IEditProfileRespondentModalProps
> = (props) => {
  const dispatch = useDispatch();

  const initialRespondentState: IInviteRespondents = {
    isInviteExternal: false,
    isInviteColleagues: false,
    isInviteOtherParticipant: false,
  };
  const [inviteRespondents, setInviteRespondents] =
    useState<IInviteRespondents>(initialRespondentState);

  useEffect(() => {
    setInviteRespondents({
      isInviteExternal: props.participantInviteExternal,
      isInviteColleagues: props.participantInviteColleagues,
      isInviteOtherParticipant: props.participantInviteOtherParticipant,
    });
  }, []);

  const handleRespondentsCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { name, checked } = e.target;

    setInviteRespondents((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const onSaveClick = (): void => {
    dispatch(setSpinner(true));

    const body: IUpdateActivityRespondentsBody = {
      id: props.id,
      participantInviteExternal: inviteRespondents.isInviteExternal,
      participantInviteColleagues: inviteRespondents.isInviteColleagues,
      participantInviteOtherParticipant:
        inviteRespondents.isInviteOtherParticipant,
    };

    updateActivityRespondents(body, dispatch)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(addToast("Updated respondents") as AnyAction);
          props.handleSaveClick(inviteRespondents);
        }
      })
      .finally(() => {
        dispatch(setSpinner(false));
        props.closeModal();
      });
  };

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(props.languageText, "Edit Respondents")}
        submitButtonText={getLanguageValue(props.languageText, "Save")}
        cancelButtonText={getLanguageValue(props.languageText, "Cancel")}
        handleSubmitClick={onSaveClick}
        handleCancelClick={props.closeModal}
      >
        <ActivityRespondentsStep
          languageText={props.languageText}
          isGroup={props.isGroup}
          respondentFromEditProfile={true}
          inviteRespondents={inviteRespondents}
          handleRespondentsCheckboxChange={handleRespondentsCheckboxChange}
          formName="editProfileRespondent"
        />
      </ModalComponent>
    </>
  );
};
